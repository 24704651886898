// breadcrumb
//

@use '../core/variables' as *;
@use '../core/functions' as func;
@use 'bootstrap' as bs;

.breadcrumbs {
  --miepref-gutter-x: 1.5rem;
  width: 100%;
  padding: 2rem calc(var(--miepref-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
  color: $color-silver;
  @each $breakpoint, $container-max-width in $container-max-widths {
    @include bs.media-breakpoint-up($breakpoint, $grid-breakpoints) {
      max-width: $container-max-width;
    }
  }
  & > *:not(:last-child) {
    margin-right: clamp(0.75rem, 2.37vw, 1.5rem);
  }
  & > *:not(:first-child) {
    margin-left: clamp(0.75rem, 2.37vw, 1.5rem);
  }
  & > a {
    font-weight: bold;
  }
  .current {
    color: $color-gray-800;
  }

  &--page_topics-show {
    @include bs.media-breakpoint-up(sm) {
      padding: 1rem calc(var(--miepref-gutter-x) * 0.5);
    }
  }
}
