// Heading
//
// heading要素には必ず上下にある程度デフォルトmarginを持つよう設計してください

@use '../core/variables' as *;
@use '../core/functions' as func;
@use 'bootstrap' as bs;

// h1-type1
//
.heading-1-1 {
  margin: 1.5rem 0 2rem;
  padding: 1.625rem 0;
  border-top: 2px solid $color-green-2;
  border-bottom: 1px solid $color-green-3;
  text-align: center;

  &__sub-txt {
    display: block;
    margin: 0 0 0.25rem;
    font-size: 1rem;
    color: $color-green-3;
    text-transform: uppercase;
    line-height: 1;
    font-family: 'montserrat_bold';
    font-weight: 700;
  }

  &__main-txt {
    display: block;
    font-size: 1.5rem;
    color: $color-green-2;
    line-height: 1.3;
    font-family: 'noto_sans';
    font-weight: 700;
  }
}

// h1-type2
//
.heading-1-2 {
  margin: 1.5rem 0 clamp(1rem, 2.5vw, 2rem);
  padding: clamp(0.75rem, 1.25vw, 1rem) clamp(1rem, 1.875vw, 1.5rem);
  color: $color-gray-800;
  background-color: $color-green-4;
  border-bottom: 1px solid $color-green-3;
  font-size: clamp(1.2rem, 1.875vw, 1.35rem);
  font-weight: bold;
  line-height: 1.8;
  word-break: break-all;
  @include bs.media-breakpoint-up(lg) {
    border-top: 4px solid $color-green;
  }

  &__kana {
    display: block;
    font-size: 0.8rem;
    font-family: 'noto_sans';
    font-weight: 400;
  }
  [data-url-path^='/season/article/'] &,
  [data-url-path^='/report/'] & {
    border: none;
  }
  &.is-advertisement {
    position: relative;
    padding-bottom: clamp(2rem, 3.125vw, 2.5rem);
    .advertisement-txt {
      position: absolute;
      right: clamp(1rem, 1.875vw, 1.5rem);
      bottom: clamp(0.75rem, 1.25vw, 1rem);
    }
  }
}

// h1-type3
//
.heading-1-3 {
  $_y-gutter: 1.25rem;

  position: relative;
  margin: 1.5rem 0 2rem;
  padding: $_y-gutter 1.5rem $_y-gutter 3rem;
  color: white;
  background-color: $color-green-2-alpha-60;
  font-size: 1.5rem;
  font-family: 'noto_sans';
  font-weight: 700;
  line-height: 1.66;

  &::before {
    position: absolute;
    top: $_y-gutter;
    left: 1.5rem;
    bottom: $_y-gutter;
    padding: $_y-gutter 0;
    width: 40px;
    content: ' ';
    border-left: 4px solid white;
  }
  &.is-advertisement {
    padding-bottom: 1.5rem;
    @include bs.media-breakpoint-up(sm) {
      padding-bottom: 1.75em;
    }
    &::before {
      bottom: calc($_y-gutter + 1.5rem);
      @include bs.media-breakpoint-up(sm) {
        bottom: calc($_y-gutter + 1.75rem);
      }
    }
    .advertisement-txt {
      position: absolute;
      right: 1.5rem;
      bottom: 0.5rem;
      @include bs.media-breakpoint-up(sm) {
        bottom: 0.75rem;
      }
    }
  }
}

// h2-type1
//
.heading-2-1 {
  color: $color-gray-800;
  font-family: 'noto_sans', sans-serif;
  font-weight: 600;
  font-size: 1.5rem;
  &::before {
    display: block;
    color: $color-green-2;
    content: attr(data-en);
    font-family: 'montserrat_bold', sans-serif;
    font-weight: 700;
    font-size: func.rem(20);
    line-height: 1.25;
    speak: none;
    text-transform: lowercase;
  }

  &.--white {
    color: white;
    &::before {
      color: white;
    }
  }

  &__txt {
    display: block;
    font-size: clamp(1.5rem, 2.5vw, 2rem);
  }
  &.text-transform-none {
    &::before {
      text-transform: none;
    }
  }
}

.heading-2-1-lead {
  margin-bottom: 0;
  color: $color-gray-800;
  line-height: 1.5;
  font-family: 'noto_sans', sans-serif;
  font-size: func.rem(14);
}

// h2-type2
//
.heading-2-2 {
  margin-bottom: 2rem;
  padding: 0 0 0.25rem 1rem;
  border-left: 5px solid $color-green-2;
  border-bottom: 1px solid $color-green-2;
  color: $color-gray-800;
  font-size: func.rem(20);
  line-height: 2;
}

// h2-type3
//
.heading-2-3 {
  margin-bottom: 2rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid $color-green-2;
  color: $color-green-2;
  font-size: func.rem(20);
  font-weight: bold;
  line-height: 1.6;
}

// h2-type4
//
.heading-2-4 {
  margin-bottom: 2rem;
  padding: 0.75rem 1rem;
  background-color: $color-green-4;
  color: $color-green;
  font-size: func.rem(20);
  font-weight: bold;
  line-height: 1.6;
  text-align: center;
}

// h2-type5
//
.heading-2-5 {
  position: relative;
  margin-bottom: 2rem;
  padding: 0.75rem 1rem 0.75rem 2.5rem;
  background-color: $color-green-2;
  color: white;
  font-size: func.rem(20);
  font-weight: bold;
  line-height: 1.6;
  &::before {
    position: absolute;
    top: 0;
    left: 0.5rem;
    width: 0.25rem;
    height: 100%;
    background: #fff;
    content: '';
  }
}

// h2-type6
//
.heading-2-6 {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  column-gap: clamp(3rem, 6.625vw, 4.5rem);
  margin-bottom: 2rem;
  padding: 1rem;
  background-color: $color-yellow;
  border-top: 2px solid;
  border-bottom: 2px solid;
  color: $color-brown;
  font-size: func.rem(28);
  font-weight: bold;
  &::before,
  &::after {
    @include bs.media-breakpoint-up(md) {
      padding-left: 1em;
      flex-shrink: 0;
      content: '◆◆◆';
      font-size: func.rem(12);
      letter-spacing: 1em;
    }
  }

  &--type_gift {
    color: white;
    background-color: $color-blue-2;
    border-color: #e3e7ff;
  }

  &--type_gourmet {
    color: $color-brown;
    background-color: $color-yellow;
    border-color: $color-brown;
  }
}

// h2-type7
//
.heading-2-7 {
  margin-bottom: 1.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid $color-green-2;
  color: $color-gray-800;
  font-size: func.rem(20);
  line-height: 1.5;
}

// h3-type-1
//
.heading-3-1 {
  position: relative;
  margin-bottom: 2rem;
  padding: 0.5rem 1rem;
  background-color: $color-green-2;
  color: white;
  font-size: func.rem(18);
  font-weight: bold;
  line-height: 2;
  &::after {
    position: absolute;
    top: 100%;
    left: 4.4%;
    display: block;
    width: 0;
    height: 0;
    border-top: 0.625rem $color-green-2 solid;
    border-right: 0.75rem transparent solid;
    border-left: 0.75rem transparent solid;
    content: '';
  }
}

// h3-type-2
//
.heading-3-2 {
  margin-bottom: 2rem;
  padding-left: 1rem;
  border-left: 0.25rem $color-green-2 solid;
  color: $color-green-2;
  font-size: func.rem(18);
  font-weight: bold;
  line-height: 2;
}

// h3-type-2
//
.heading-3-3 {
  margin-bottom: 2rem;
  padding: 0.5rem 1rem;
  background-color: $color-green-4;
  border-top: 0.25rem $color-green-2 solid;
  border-bottom: func.rem(1) $color-green-2 solid;
  color: $color-green-2;
  font-size: func.rem(18);
  font-weight: bold;
  line-height: 2;
  text-align: center;
}

[class^='heading'] {
  &.has-ad {
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      flex-shrink: 0;
      width: fit-content;
      margin-left: 10px;
    }
  }
}
