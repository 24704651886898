// card
//

@use '../core/variables' as *;
@use 'bootstrap' as bs;

.card {
  border: none;
  &:hover {
    .card-img {
      img {
        @media (hover: hover) {
          transform: scale(1.05);
        }
      }
      &.ratio {
        img {
          @media (hover: hover) {
            transform: translateX(-50%) scale(1.05);
          }
        }
      }
    }
  }
}

.card-text {
  font-size: 1rem;
  @include bs.media-breakpoint-down(md) {
    font-size: 0.875rem;
  }
}

.card-img {
  position: relative;
  border-radius: clamp(0.6rem, 1.875vw, 0.8rem);
  overflow: hidden;
  img {
    transition: transform 0.3s;
    transform: translate3d(0, 0, 0);
  }
  &.ratio {
    img {
      width: auto;
      left: 50%;
      transform: translateX(-50%) translate3d(0, 0, 0);
    }
  }
}

.card-body {
  padding: 0.75rem 0 0;
  @include bs.media-breakpoint-down(md) {
    padding: 0.2rem 0 0;
  }
}

.card-description {
  position: absolute;
  top: auto;
  bottom: 0;
  left: 0;
  display: grid;
  place-items: center;
  width: 100%;
  height: auto;
  margin-bottom: 0;
  padding: clamp(0.25rem, 0.625vw, 0.5rem) 0.5rem;
  background: linear-gradient(rgba(#020101, 0.3) 0%, rgba(#000, 0.6) 100%);
  color: #fff;
  line-height: 1.25;
  text-align: center;
  @include bs.media-breakpoint-up(lg) {
    min-height: 3.5rem;
  }
}
