/*
  * Global Footer
  *
  * [本ソースの転用先]
  * - 観光三重公式サイト
  * - 一部の観光三重特設サイト
  * - 外部サービス「たびくら」
  *
  * [記述条件]
  * - cssを記載する（scssを用いない)
  * - デザインクラス名にmiepref- というプレフィクスを用いる
  * - Bootstrapのような外部フレームワークを用いない
  * - 導入先サイトによって条件が異なる可能性があるためremは利用しない
*/

:root {
  --mieprefg-color-green-1: #2cac99;
  --mieprefg-color-green-2: #1b8c98;
  --mieprefg-color-green-3: #abd5d9;
  --mieprefg-color-green-4: #e4f4f5;
  --mieprefg-color-gray-50: #fafafa;
  --mieprefg-color-gray-200: #e5e5e5;
  --mieprefg-color-gray-300: #d4d4d4;
  --mieprefg-color-gray-600: #525252;
  --mieprefg-color-gray-800: #333;
  --mieprefg-color-gray-900: #050505;
  --mieprefg-color-yellow: #ffdb65;
}

.miepref-site-footer {
  padding: 64px 0;
  width: 100%;
  height: auto;
  text-align: center;
  color: var(--mieprefg-color-gray-800);
  background-color: #f3f3f3;
  font-family: 'Noto Sans JP', 'noto_sans', sans-serif;
}

.miepref-site-footer-container {
  width: 100%;
  box-sizing: border-box;
  padding: 0 12px;
  margin: 0 auto;
}
@media (min-width: 576px) {
  .miepref-site-footer-container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .miepref-site-footer-container {
    max-width: 720px;
  }
}
@media (min-width: 1012px) {
  .miepref-site-footer-container {
    max-width: 980px;
  }
}
@media (min-width: 1280px) {
  .miepref-site-footer-container {
    max-width: 1216px;
  }
}

/* .miepref-site-footer-menu-nav {} */

.miepref-site-footer-menu {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
}

.miepref-site-footer-menu-list {
  flex: 0 0 auto;
  margin: 0 0 32px;
  width: 100%;
  padding: 0 12px;
  list-style: none;
}
@media (min-width: 768px) {
  .miepref-site-footer-menu-list {
    width: 50%;
  }
}
@media (min-width: 1012px) {
  .miepref-site-footer-menu-list {
    width: 20%;
  }
}

.miepref-site-footer-menu-list__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 8px;
  margin: 0 0 20px;
  line-height: 1.2;
  font-weight: 700;
  border-bottom: 1px solid var(--mieprefg-color-gray-800);
  text-align: left;
  font-size: 16px;
  cursor: pointer;
}
@media (min-width: 768px) {
  .miepref-site-footer-menu-list__item {
    cursor: default;
  }
}

.miepref-site-footer-menu-list__item-txt {
  font-size: 16px;
}

.miepref-site-footer-menu-list__item-icon {
  width: 16px;
  margin: 0 12px 0 0;
  height: auto;
}
@media (min-width: 768px) {
  .miepref-site-footer-menu-list__item-icon {
    display: none;
  }
}
[data-role='miepref-footer-menu-opener'][data-state='active'] .miepref-site-footer-menu-list__item-icon {
  transform: rotate(180deg);
}

.miepref-site-footer-sub-menu-list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: none;
}
.miepref-site-footer-sub-menu-list[data-state='active'] {
  display: block;
  background-color: transparent;
  animation-name: miepref-global-footer-contents-fade;
  animation-duration: 0.3s;
  animation-delay: 0;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
  opacity: 0;
}

@keyframes miepref-global-footer-contents-fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@media (min-width: 768px) {
  .miepref-site-footer-sub-menu-list {
    display: block;
  }
}

.miepref-site-footer-sub-menu-list__item {
  margin: 0 0 4px;
  text-align: left;
  line-height: 2;
}

.miepref-site-footer-sub-menu-list__item-link {
  line-height: 1.2;
  color: var(--mieprefg-color-gray-600) !important;
  text-decoration: underline !important;
  transition: all 0.3s ease;
  font-size: 16px;
  font-weight: 400;
}

.miepref-site-footer-sub-menu-list__item-link:hover {
  color: var(--mieprefg-color-gray-800) !important;
  text-decoration: underline !important;
}

.miepref-site-footer-copyright {
  font-size: 20px;
  font-weight: 700;
}

.miepref-site-footer-address {
  font-size: 16px;
  line-height: 1.4;
}

.totop-btn {
  position: fixed;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 8px;
  padding: 12px;
  padding-bottom: 16px;
  background: #00a29a;
  text-decoration: none;
  transform: translateY(4px);
  transition: transform 0.3s;
  z-index: +2;
  .arrow {
    display: block;
    width: 4px;
    height: 24px;
    border-top: 1px #fff solid;
    border-left: 1px #fff solid;
    transform: skewY(45deg);
  }
  .txt {
    display: block;
    color: #fff;
    font-size: 12px;
    font-weight: bold;
    writing-mode: vertical-rl;
  }
  &:hover {
    text-decoration: none;
    @media (hover: hover) {
      transform: translateY(0);
    }
  }
  &--top {
    opacity: 1;
    pointer-events: auto;
    // stylelint-disable-next-line
    transition: opacity 0.3s, transform 0.3s;
    &.is-mv-show {
      opacity: 0;
      pointer-events: none;
    }
  }
}
