// shokutabi
//

@use '../core/variables' as *;
@use '../core/functions' as func;
@use 'bootstrap' as bs;

// shokutabi-index
//
.shokutabi-report {
  background: url('../../assets/images/shokutabi/index-report-bg-1.webp');
  padding: 3rem 0;
}

// shokutabi-youtube
//
.shokutabi-youtube-mv {
  background: url('../../assets/images/shokutabi/youtube-mv-bg-1.webp') repeat center center/contain;
  position: relative;
  padding: 6rem 0 3rem;

  @include bs.media-breakpoint-up(md) {
    padding: 3rem 0;
  }
}

.shokutabi-youtube-contents {
  background: url('../../assets/images/shokutabi/youtube-contents-bg-1.webp');
  padding: 3rem 0;
}

.shokutabi-youtube-comment {
  position: relative;

  @include bs.media-breakpoint-down(md) {
    padding: 20px;
    background: #fff445;
    border: 3px solid #000;
    box-sizing: border-box;

    &::before {
      content: '';
      position: absolute;
      left: 50%;
      border: 30px solid transparent;
      top: -60px;
      border-bottom-color: #000;
      margin-left: -30px;
    }

    &::after {
      content: '';
      position: absolute;
      left: 50%;
      border: 30px solid transparent;
      top: -55px;
      border-bottom-color: #fff445;
      margin-left: -30px;
    }
  }
}
.shokutabi-youtube-recommend {
  background: url('../../assets/images/shokutabi/youtube-recommend-bg-1.webp');
  padding: 3rem 0;
}

// shokutabi-blogs
//
.shokutabi-blogs-mv-bottom {
  background: url('../../assets/images/shokutabi/blogs-bg-1.webp');
  padding: 1rem 0;
}

.shokutabi-blogs-heading {
  background: url('../../assets/images/shokutabi/blogs-bg-2.webp');
  padding: 3rem 0;
}

.shokutabi-blogs-items-title {
  background: url('../../assets/images/shokutabi/blogs-bg-1.webp');

  &::after {
    content: '';
    background: url('../../assets/images/shokutabi/blogs-bg-3.webp');
    display: block;
    height: 0.5rem;
    width: 100%;
  }

  &__text {
    background: url('../../assets/images/shokutabi/blogs-bg-3.webp');
    color: $color-white;
    padding: 0.5rem 2rem 0;
    margin: 0;
  }
}

// shokutabi-blog
//
.shokutabi-blog-bg-2 {
  background: url('../../assets/images/shokutabi/blogs-bg-2.webp');
}

.shokutabi-blog-bg-3 {
  background: url('../../assets/images/shokutabi/blogs-bg-3.webp');
}
