// Buttons
//

@use '../core/variables' as *;
@use '../core/functions' as func;
@use 'bootstrap' as bs;

// base
//

.icon-pos-r {
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
}
.icon-pos-l {
  position: absolute;
  top: 50%;
  left: 1rem;
  transform: translateY(-50%);
}

// btn-cmn
//
// btn-selectと同じ内容を割り当て

.btn-cmn {
  display: inline-block;
  min-width: 11rem;
  padding: 0.75rem 1rem;
  border: 1px $color-green-2 solid;
  border-radius: 0.25rem;
  background: none;
  color: $color-green-2;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  transition: background 0.3s;

  &:hover {
    @media (hover: hover) {
      color: #fff;
      background: $color-green-2;
      text-decoration: none;
    }
  }
  &:active {
    @media (hover: none) {
      color: #fff;
      background: $color-green-2;
      text-decoration: none;
    }
  }

  &.is-selected {
    background: $color-green-2;
    color: #fff;
  }
}

// btn-select
//

.btn-select {
  display: inline-block;
  min-width: 11rem;
  padding: clamp(0.5rem, 0.9375vw, 0.75rem) clamp(0.5rem, 1.25vw, 1rem);
  border: 1px $color-green-2 solid;
  border-radius: 0.25rem;
  background: none;
  color: $color-green-2;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  transition: background 0.3s;
  font-size: 0.8rem;

  @include bs.media-breakpoint-up(md) {
    font-size: 1rem;
  }

  &:hover {
    @media (hover: hover) {
      color: #fff;
      background: $color-green-2;
      text-decoration: none;
    }
  }
  &:active {
    @media (hover: none) {
      color: #fff;
      background: $color-green-2;
      text-decoration: none;
    }
  }

  &[data-state='active'] {
    background: $color-green-2;
    color: #fff;
  }
}

// btn-more
//

.btn-more {
  position: relative;
  display: grid;
  place-items: center;
  min-height: 3.5rem;
  padding: 0.5rem clamp(1rem, 2.5vw, 2rem);
  background: #fff;
  border: 1px $color-gray-800 solid;
  border-radius: 0.25rem;
  color: $color-gray-900;
  font-size: clamp(0.875rem, 1.25vw, 1rem);
  text-decoration: none;
  /* stylelint-disable-next-line */
  transition:
    background 0.3s,
    border-color 0.3s,
    color 0.3s;

  &:hover {
    @media (hover: hover) {
      background: #2cac99;
      border-color: #2cac99;
      color: #fff;
      text-decoration: none;
    }
  }
  &:active {
    @media (hover: none) {
      background: #2cac99;
      border-color: #2cac99;
      color: #fff;
      text-decoration: none;
    }
  }
}

// btn-more-2
//

.btn-more-2 {
  position: relative;
  display: grid;
  place-items: center;
  min-height: 3.5rem;
  padding: 0.5rem 2rem;
  border-radius: 0.25rem;
  background-color: $color-green-4;
  color: $color-green-2;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  /* stylelint-disable-next-line */
  transition:
    background 0.3s,
    color 0.3s;
  &::before {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 2rem;
    height: 2rem;
    clip-path: polygon(0 0, 0% 100%, 100% 100%);
    background: $color-green-2;
    content: '';
    transition: background 0.3s;
  }

  &:hover {
    @media (hover: hover) {
      background: $color-green-2;
      color: #fff;
      text-decoration: none;
    }
    &::before {
      @media (hover: hover) {
        background: #fff;
      }
    }
  }
  &:active {
    @media (hover: none) {
      background: $color-green-2;
      color: #fff;
      text-decoration: none;
    }
    &::before {
      @media (hover: none) {
        background: #fff;
      }
    }
  }
}

// btn-back
//

.btn-back {
  position: relative;
  display: grid;
  place-items: center;
  width: 100%;
  min-height: 3.5rem;
  padding: 0.5rem 2rem;
  background: none;
  border: 1px $color-gray-800 solid;
  border-radius: 0.25rem;
  color: $color-gray-900;
  font-size: rem(14);
  text-decoration: none;
  /* stylelint-disable-next-line */
  transition:
    background 0.3s,
    border-color 0.3s,
    color 0.3s;
  &:hover {
    @media (hover: hover) {
      background: $color-green;
      border-color: $color-green;
      color: #fff;
      text-decoration: none;
    }
  }
  &:active {
    @media (hover: none) {
      background: $color-green;
      border-color: $color-green;
      color: #fff;
      text-decoration: none;
    }
  }
}

// btn-dl
//

.btn-dl {
  position: relative;
  display: grid;
  align-items: center;
  width: 100%;
  min-height: 3.5rem;
  padding: 0.5rem 2rem 0.5rem 1rem;
  background: $color-gray-100;
  border: none;
  border-radius: 0.25rem;
  box-shadow: 2px 2px 4px rgba(#000, 0.16);
  color: $color-gray-800;
  text-decoration: none;
  /* stylelint-disable-next-line */
  transition:
    background 0.3s,
    box-shadow 0.3s;
  &:hover {
    @media (hover: hover) {
      background: $color-silver;
      box-shadow: 2px 2px 4px rgba(#000, 0);
      color: $color-gray-800;
      text-decoration: none;
    }
  }
  &:active {
    @media (hover: none) {
      background: $color-silver;
      box-shadow: 2px 2px 4px rgba(#000, 0);
      color: $color-gray-800;
      text-decoration: none;
    }
  }
}

// btn-add-badge
//

.btn-add-badge {
  position: relative;
  display: grid;
  place-items: center;
  width: 2.5rem;
  height: 2.5rem;
  background: #fff;
  box-shadow: 2px 2px 4px rgba(#000, 0.16);
  border-radius: 50%;
  color: #fda4af;
  text-decoration: none;
  /* stylelint-disable-next-line */
  transition:
    background 0.3s,
    color 0.3s;
  &:hover {
    @media (hover: hover) {
      background: #fecdd3;
      color: #fb7185;
    }
  }
  &:active {
    @media (hover: none) {
      background: #fecdd3;
      color: #fb7185;
    }
  }
  &.is-selected {
    background: #bcbcbc;
    box-shadow: 2px 2px 4px rgba(#000, 0.16) inset;
    color: #fff;
  }
}

// btn-add
//

.btn-add {
  position: relative;
  display: grid;
  place-items: center;
  width: 100%;
  min-height: 3.5rem;
  padding: 0.5rem 2rem;
  background: #fff;
  border: 1px $color-gray-800 solid;
  border-radius: 0.25rem;
  color: $color-gray-800;
  text-decoration: none;
  transition: background 0.3s;
  [class^='icon'] {
    color: #fda4af;
  }
  &:hover {
    @media (hover: hover) {
      background: #fdcdd3;
      text-decoration: none;
      color: $color-gray-800;
    }
  }
  &:active {
    @media (hover: none) {
      background: #fdcdd3;
      text-decoration: none;
      color: $color-gray-800;
    }
  }
  &.is-selected {
    background: #bcbcbc;
    border: none;
    box-shadow: 2px 2px 4px rgba(#000, 0.16) inset;
    color: $color-gray-800;
    [class^='icon'] {
      color: #fff;
    }
  }
  [data-controller='favorite-count'] {
    position: absolute;
    top: 50%;
    right: 2.5rem;
    font-size: 0.75rem;
    transform: translateY(-50%);
  }
}

// btn-reserve
//

.btn-reserve {
  position: relative;
  display: grid;
  place-items: center;
  width: 100%;
  min-height: 3.5rem;
  padding: 0.5rem 2rem;
  background: #e3e7ff;
  border: 1px $color-blue solid;
  border-radius: 0.25rem;
  color: $color-gray-900;
  text-decoration: none;
  transition: background 0.3s;
  [class^='icon'] {
    color: $color-blue;
    transition: color 0.3s;
  }
  &:hover {
    @media (hover: hover) {
      color: $color-gray-900;
      background: #838fdb;
      text-decoration: none;
    }
    [class^='icon'] {
      @media (hover: hover) {
        color: $color-gray-900;
      }
    }
  }
  &:active {
    @media (hover: none) {
      color: $color-gray-900;
      background: #838fdb;
      text-decoration: none;
    }
    [class^='icon'] {
      @media (hover: none) {
        color: $color-gray-900;
      }
    }
  }
}

// btn-map
//

.btn-map {
  position: relative;
  display: grid;
  place-items: center;
  width: 100%;
  min-height: 3.5rem;
  padding: 0.5rem clamp(1.5rem, 2.5vw, 2rem);
  background: $color-green;
  border: none;
  border-radius: 0.25rem;
  color: #fff;
  font-size: clamp(0.875rem, 1.25vw, 1rem);
  text-decoration: none;
  transition: background 0.3s;
  &:hover {
    @media (hover: hover) {
      background: $color-green-2;
      text-decoration: none;
      color: #fff;
    }
  }
  &:active {
    @media (hover: none) {
      background: $color-green-2;
      text-decoration: none;
      color: #fff;
    }
  }
}

// btn-map-2
//

.btn-map-2 {
  position: relative;
  display: grid;
  place-items: center;
  width: 100%;
  min-height: 3.5rem;
  padding: 0.5rem 2rem;
  background: #fff;
  border: 1px $color-gray-800 solid;
  border-radius: 0.25rem;
  color: $color-gray-900;
  font-size: rem(14);
  text-decoration: none;
  /* stylelint-disable-next-line */
  transition:
    background 0.3s,
    border-color 0.3s,
    color 0.3s;
  &:hover {
    @media (hover: hover) {
      background: $color-green-2;
      border-color: $color-green-2;
      color: #fff;
      text-decoration: none;
    }
  }
  &:active {
    @media (hover: none) {
      background: $color-green-2;
      border-color: $color-green-2;
      color: #fff;
      text-decoration: none;
    }
  }
}

// btn-route
//

.btn-route {
  position: relative;
  display: grid;
  place-items: center;
  width: 100%;
  min-height: 3.5rem;
  padding: 0.5rem 2rem;
  background: $color-green-4;
  border: 1px #2cac99 solid;
  border-radius: 0.25rem;
  color: $color-gray-900;
  text-decoration: none;
  transition: background 0.3s;
  [class^='icon'] {
    color: $color-green;
    transition: color 0.3s;
  }
  &:hover {
    @media (hover: hover) {
      color: $color-gray-900;
      background: $color-green-3;
      text-decoration: none;
    }
    [class^='icon'] {
      @media (hover: hover) {
        color: $color-gray-900;
      }
    }
  }
  &:active {
    @media (hover: none) {
      color: $color-gray-900;
      background: $color-green-3;
      text-decoration: none;
    }
    [class^='icon'] {
      @media (hover: none) {
        color: $color-gray-900;
      }
    }
  }
}

// btn-copy-course
//

.btn-copy-course {
  position: relative;
  display: grid;
  place-items: center;
  width: 100%;
  min-height: 3.5rem;
  padding: 0.5rem 2rem;
  background: #fff;
  border: 1px $color-gray-800 solid;
  border-radius: 0.25rem;
  color: $color-gray-900;
  font-size: rem(14);
  text-decoration: none;
  /* stylelint-disable-next-line */
  transition:
    background 0.3s,
    border-color 0.3s,
    color 0.3s;
  &:hover {
    @media (hover: hover) {
      background: $color-green;
      border-color: $color-green;
      color: #fff;
      text-decoration: none;
    }
  }
  &:active {
    @media (hover: none) {
      background: $color-green;
      border-color: $color-green;
      color: #fff;
      text-decoration: none;
    }
  }
}

// btn-submit
//

.btn-submit {
  position: relative;
  display: grid;
  place-items: center;
  width: 100%;
  min-height: 3.5rem;
  padding: 0.5rem 2rem;
  background: $color-yellow;
  border: none;
  border-radius: 0.25rem;
  color: $color-gray-900;
  font-size: rem(14);
  text-decoration: none;
  transition: background 0.3s;
  &[disabled] {
    background: $color-silver;
    cursor: not-allowed;
  }
  &:hover:not(&[disabled]) {
    @media (hover: hover) {
      background: $color-gold;
      color: $color-gray-900;
      text-decoration: none;
    }
  }
  &:active:not(&[disabled]) {
    @media (hover: none) {
      background: $color-gold;
      color: $color-gray-900;
      text-decoration: none;
    }
  }
}

// btn-form-select
//

.btn-form-select {
  width: 100%;
  display: inline-block;
  padding: 0.75rem 1rem;
  border: 1px $color-gray-800 solid;
  border-radius: 0.25rem;
  background: none;
  color: $color-gray-800;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  transition: background 0.3s;

  &:hover {
    @media (hover: hover) {
      color: $color-green-2;
      background: $color-green-4;
      text-decoration: none;
    }
  }

  &:active {
    @media (hover: none) {
      color: #fff;
      background: $color-gray-800;
      text-decoration: none;
    }
  }

  &[data-state='active'] {
    color: #fff;
    background: $color-green-2;
    text-decoration: none;
    border: 1px $color-green-2 solid;
  }
}

// btn-form-search
//

.btn-form-search {
  display: grid;
  place-items: center;
  width: 100%;
  min-height: 3.5rem;
  padding: 0.5rem 2rem;
  border-radius: 0.25rem;
  background: $color-gray-800;
  border: none;
  color: #fff;
  text-decoration: none;
  transition: background 0.3s;
  &:hover {
    @media (hover: hover) {
      background: $color-gray-900;
      color: #fff;
      text-decoration: none;
    }
  }
  &:active {
    @media (hover: none) {
      background: $color-gray-900;
      color: #fff;
      text-decoration: none;
    }
  }
}

// btn-form-reset
//

.btn-form-reset {
  display: grid;
  place-items: center;
  width: 100%;
  min-height: 3.5rem;
  padding: 0.5rem 2rem;
  border-radius: 0.25rem;
  background: $color-gray-100;
  border: none;
  color: $color-gray-800;
  text-decoration: none;
  transition: background 0.3s;
  &:hover {
    @media (hover: hover) {
      background: $color-silver;
      color: $color-gray-800;
      text-decoration: none;
    }
  }
  &:active {
    @media (hover: none) {
      background: $color-silver;
      color: $color-gray-800;
      text-decoration: none;
    }
  }
}

// btn-sort
//
.btn-sort {
  display: flex;
  justify-content: end;
  align-items: center;
  margin-bottom: 0.5rem;
  font-size: rem(14);
  &__ttl {
    margin-right: 1rem;
    margin-bottom: 0;
    font-size: func.rem(14);
    font-weight: bold;
  }
}
.btn-sort-list {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  margin: 0;
  padding: 0;
  &__item {
    display: grid;
    place-items: center;
    width: 4.5rem;
    height: 1.75rem;
    margin: 0;
    padding: 0;
    background: #fff;
    border: 1px $color-gray-800 solid;
    border-radius: 0.25rem;
    color: $color-gray-800;
    font-weight: bold;
    text-decoration: none;
    transition: background 0.3s;

    &.--sm {
      font-size: 0.75rem;
    }
    &:hover {
      @media (hover: hover) {
        color: #fff;
        background: $color-green-2;
        border-color: $color-green-2;
        text-decoration: none;
      }
    }
    &:active {
      @media (hover: none) {
        color: #fff;
        background: $color-green-2;
        border-color: $color-green-2;
        text-decoration: none;
      }
    }
    &.is-current {
      pointer-events: none;
      background: $color-green-2;
      border-color: $color-green-2;
      color: #fff;
    }
  }
}

// btn-login
//
// [note] 名称はloginとなっているが至る所で利用
// (意味上の利用 ではなく デザインとしての利用)

.btn-login {
  position: relative;
  display: grid;
  align-items: center;
  width: 100%;
  min-height: 3.5rem;
  padding: 0.5rem 2rem 0.5rem 1rem;
  background: white;
  border: 1px solid $color-gray-800;
  border-radius: 0.25rem;
  color: $color-gray-800;
  text-decoration: none;
  /* stylelint-disable-next-line */
  transition:
    background 0.3s,
    box-shadow 0.3s;
  &:hover {
    @media (hover: hover) {
      background: $color-green-4;
      color: $color-gray-800;
      text-decoration: none;
    }
  }
  &:active {
    @media (hover: none) {
      background: $color-green-4;
      color: $color-gray-800;
      text-decoration: none;
    }
  }

  &.--disabled {
    color: $color-gray-400;
    border: 1px solid $color-gray-300;
    cursor: not-allowed;

    &:hover {
      @media (hover: hover) {
        background: white;
        color: $color-gray-400;
        text-decoration: none;
      }
    }
  }
}

// btn-account-reset
//

.btn-account-reset {
  position: relative;
  display: grid;
  align-items: center;
  width: 100%;
  min-height: 3.5rem;
  padding: 0.5rem 2rem 0.5rem 1rem;
  background: $color-gray-300;
  border: 1px solid $color-gray-300;
  border-radius: 0.25rem;
  color: $color-gray-500;
  text-decoration: none;
  /* stylelint-disable-next-line */
  transition:
    background 0.3s,
    box-shadow 0.3s;
  &:hover {
    @media (hover: hover) {
      background: $color-gray-400;
      border: 1px solid $color-gray-400;
      color: $color-gray-600;
      text-decoration: none;
    }
  }
  &:active {
    @media (hover: none) {
      background: $color-gray-400;
      border: 1px solid $color-gray-400;
      color: $color-gray-600;
      text-decoration: none;
    }
  }
}
