@use '../core/variables' as *;
@use '../core/functions' as func;
@use 'bootstrap' as bs;

body[data-url-path='/season/festival'] {
  .miepref-site-main {
    margin-bottom: 0;
  }
}

.festival-contents {
  padding-bottom: clamp(32px, 6.25vw, 80px);
  background: url('../../assets/images/festival/bg_contents.webp') repeat-y center top / 100% auto;
}

.festival-contents-header {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: clamp(64px, 5.625vw, 72px);
  &__title {
    order: 2;
    margin-bottom: clamp(16px, 2.5vw, 32px);
    font-family: serif;
    font-size: clamp(24px, 2.5vw, 32px);
    font-weight: bold;
    text-align: center;
  }
  &__mv {
    order: 1;
    width: 100%;
    margin-bottom: clamp(20px, 3.125vw, 40px);
    @include bs.media-breakpoint-up(md) {
      max-width: 720px;
      margin-inline: auto;
    }
    @include bs.media-breakpoint-up(lg) {
      max-width: 980px;
    }
    @include bs.media-breakpoint-up(xl) {
      max-width: 1216px;
    }
    img {
      width: 100%;
    }
  }
  &__description {
    order: 3;
    font-family: serif;
    line-height: 2;
  }
}

.festival-contents-body {
  .btn-form-select,
  .archive-filter__keyword {
    background: #fff;
  }
  .card {
    background: none;
  }
  .accordion-button {
    position: relative;
    display: grid;
    place-items: center;
    width: min(100%, 588px);
    margin-inline: auto;
    background: #fff;
    border: 1px $color-gray-800 solid;
    border-radius: 4px;
    color: $color-gray-800;
    &::after {
      position: absolute;
      top: 50%;
      right: 16px;
      transform: translateY(-50%) rotate(0);
    }
    &.is-open {
      &::after {
        transform: translateY(-50%) rotate(180deg);
      }
    }
  }
}

.festival-contents-section {
  margin-bottom: clamp(64px, 5.625vw, 72px);
  &__title {
    margin-bottom: clamp(16px, 2.5vw, 32px);
    font-family: serif;
    font-size: clamp(24px, 2.5vw, 32px);
    font-weight: bold;
    text-align: center;
  }
}

.festival-search-form {
  margin-bottom: clamp(16px, 2.5vw, 32px);
  .btn-form-search {
    min-height: 50px;
  }
}

.btn-festival {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 120px;
  padding: 16px 48px 16px 24px;
  background: #fff;
  box-shadow: 0 3px 6px rgba(#000, 0.16);
  color: #000;
  font-family: serif;
  font-size: 20px;
  font-weight: bold;
  text-decoration: none;
}
