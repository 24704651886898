// season
//

@use '../core/variables' as *;
@use '../core/functions' as func;
@use 'bootstrap' as bs;

[data-url-path^='/season/article/'] {
  [data-role='circle-swiper'] {
    .swiper-wrapper {
      padding-bottom: 1px;
      padding-left: calc(24% + 12px);
      @include bs.media-breakpoint-up(md) {
        padding-left: calc(7% + 12px);
      }
      @include bs.media-breakpoint-up(lg) {
        padding-left: calc(11.5% + 12px);
      }
    }
  }
}

.season-contents {
  padding-bottom: 5rem;
  background: #f9f2e0;
  .swiper-wrapper {
    padding-bottom: 1px;
    padding-left: calc(24% + 12px);
    @include bs.media-breakpoint-up(md) {
      padding-left: calc(9.2% + 12px);
    }
    @include bs.media-breakpoint-up(lg) {
      padding-left: calc(7.2% + 12px);
    }
  }
  &--gourmet {
    .season-contents-header__description {
      color: $color-brown;
    }
    .season-contents-block {
      .btn-more {
        background: url('../../assets/images/common/bg_pattern02.webp');
        border: none;
        color: #fff;
        & > span {
          color: #525252;
        }
      }
    }
  }
  &--gift {
    .season-contents-header__description {
      color: $color-blue-2;
    }
    .season-contents-block {
      .btn-more {
        background: url('../../assets/images/common/bg_pattern03.webp');
        border: none;
        color: #fff;
        & > span {
          color: #525252;
        }
      }
    }
  }
}
.season-contents-header {
  margin-bottom: 2.5rem;
  padding-top: 2rem;
  &__title {
    margin: 0 auto;
    width: 60%;
    transform: translateY(-62%);
    @include bs.media-breakpoint-up(md) {
      text-align: center;
    }
  }
  &__description {
    margin: 0 auto;
    font-weight: bold;
    text-align: center;
    transform: translateY(-30%);
    @include bs.media-breakpoint-up(md) {
      width: 75%;
      transform: translateY(-3rem);
    }
  }
}
.season-contents-header-mv {
  display: grid;
  gap: clamp(0.5rem, 1.875vw, 1.5rem);
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-template-areas:
    'item1 item3 item4 item5'
    'item2 item3 item4 item5';
  &__item {
    border-radius: 0.5rem;
    overflow: hidden;
    @for $i from 1 through 5 {
      &:nth-of-type(#{$i}) {
        grid-area: item#{$i};
      }
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.season-pagenav {
  margin: 0 auto 6rem;
  padding: 0 12px;
  @include bs.media-breakpoint-up(md) {
    margin-bottom: 11.25rem;
    width: 75%;
    max-width: 888px;
  }
}

.season-contents-block {
  padding: 0 1.5rem;
  background: url('../../assets/images/season/gourmet/bg_season-contents-block.webp') no-repeat top right;
  &__title {
    margin: 0 0 3.5rem;
    color: #a67a00;
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
  }
}
