// pamphlet
//

@use '../core/variables' as *;
@use '../core/functions' as func;
@use 'bootstrap' as bs;

[data-url-path='/traffic'] {
  .tab-cmn-list {
    justify-content: space-between;
    column-gap: 0.25rem;
    padding-inline: clamp(12px, calc(50% - (270px - 12px)), 100vw);
    @include bs.media-breakpoint-up(md) {
      justify-content: start;
      column-gap: clamp(0.75rem, 1.875vw, 1.5rem);
      padding-inline: calc(50% - (360px - 12px));
    }
    @include bs.media-breakpoint-up(lg) {
      padding-inline: calc(50% - (490px - 12px));
    }
    @include bs.media-breakpoint-up(xl) {
      padding-inline: calc(50% - (608px - 12px));
    }
  }
  .tab-cmn-list-item {
    flex-grow: 1;
    @include bs.media-breakpoint-up(md) {
      flex-grow: 0;
      width: clamp(7rem, 21.875vw, 17.5rem);
    }
  }
}
