// SNS Share Buttons
//
// 詳細記事系に配置されるSNSボタン群

// .share-btn-lists {}

.share-btn-list {
  display: flex;
  column-gap: 1rem;
  width: fit-content;
  &__item {
    &--facebook {
      .fb_iframe_widget {
        span {
          vertical-align: inherit !important;
        }
      }
    }
  }
}
