// mixins
//

@use 'bootstrap' as bs;

// Container Gutter Style (Bootstrap Extends)
//
@mixin col-space($m: 0.75) {
  $spacer: bs.$spacer * $m;

  & > [class*='row'] {
    margin-right: -($spacer * $m);
    margin-left: -($spacer * $m);

    @include bs.media-breakpoint-up(lg) {
      margin-right: -$spacer;
      margin-left: -$spacer;
    }

    & > [class*='col'] {
      padding-left: $spacer * $m;
      padding-right: $spacer * $m;

      @include bs.media-breakpoint-up(lg) {
        padding-left: $spacer;
        padding-right: $spacer;
      }
    }
  }
}
