// Top Page Components
//
// トップページにのみ依存するデザイン

@use '../core/variables' as *;
@use 'bootstrap' as bs;
@use '../core/functions' as func;

// mv
//

[data-role='top-mv-swiper'] {
  @include bs.media-breakpoint-up(md) {
    margin-bottom: 1.5rem;
  }
  [class^='top-mv-carousel-nav'] {
    width: clamp(1.75rem, 3.75vw, 3rem);
    height: clamp(1.75rem, 3.75vw, 3rem);
    z-index: 10;
    @include bs.media-breakpoint-up(md) {
      top: auto;
      bottom: 0;
      transform: translateY(0);
    }
  }
  .top-mv-carousel-nav-prev {
    @include bs.media-breakpoint-up(md) {
      left: clamp(1rem, 2.5vw, 2rem);
    }
  }
  .top-mv-carousel-nav-next {
    @include bs.media-breakpoint-up(md) {
      right: clamp(1rem, 2.5vw, 2rem);
    }
  }
}
.top-mv-item {
  position: relative;
  display: block;
  padding: 0.75rem 0.75rem 0;
  @include bs.media-breakpoint-up(md) {
    margin-bottom: 0;
    padding: 0;
  }
  &__img {
    position: relative;
    width: 100%;
    &::before {
      display: block;
      padding-bottom: 100%;
      content: '';
      @include bs.media-breakpoint-up(md) {
        padding-bottom: calc(100vh - 64px);
      }
    }
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  &__txt {
    position: absolute;
    right: 0;
    bottom: 3rem;
    left: 0;
    display: grid;
    place-items: center;
    width: clamp(9.5rem, 60vw, 45rem);
    margin-inline: auto;
    padding: 0.5rem;
    background: rgba(#fff, 0.6);
    color: $color-gray-900;
    text-decoration: none;
    transition: background 0.3s;
  }
  &:hover {
    .top-mv-item__txt {
      @media (hover: hover) {
        background: #fff;
        color: $color-gray-900;
        text-decoration: none;
      }
    }
  }
}
.top-mv-carousel-pagenation {
  bottom: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 0.75rem;
  height: 3rem;
  .swiper-pagination-bullet {
    width: 0.25rem;
    height: 0.25rem;
    margin: 0 !important;
    background: rgba(#f4f4f4, 0.8);
    opacity: 1;
    &.swiper-pagination-bullet-active {
      width: 0.5rem;
      height: 0.5rem;
      background: rgba(#fff, 0.95);
    }
  }
}
[data-role='top-mv-thumb-swiper'] {
  margin-top: 0.75rem;
  padding-right: calc(25% + 12px);
  @include bs.media-breakpoint-up(md) {
    max-height: 0;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }
}

// 画面幅が広い時のMVまわりデザイン違い
.miepref-global-header--home {
  @media (min-width: 1160px) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
  }
  &.is-xl-absolute {
    @media (min-width: 1160px) {
      position: absolute;
      top: calc(100vh - 100px);
      height: 100px;
    }
    .miepref-global-header-logo {
      @media (min-width: 1160px) {
        display: none;
      }
    }
    .miepref-global-header-main-container {
      @media (min-width: 1160px) {
        height: 52px;
      }
    }
    .miepref-global-header-menu {
      padding-left: 0;
    }
    .miepref-global-header-util-container {
      @media (min-width: 1160px) {
        display: none;
      }
    }
  }
}

.top-mv {
  @media (min-width: 1160px) {
    display: flex;
    height: 100vh;
    padding-bottom: 100px;
  }
  &__carousel-area {
    @media (min-width: 1160px) {
      width: calc(calc(100vh - 100px) * 1.509);
      min-width: calc(calc(calc(100vh - 100px) * 1.509) - 224px);
    }
    .top-mv-item__img {
      &::before {
        @media (min-width: 1160px) {
          padding-bottom: calc(100vh - 100px);
        }
      }
    }
  }
}
.top-mv-item {
  &__img {
    &::before {
      @media (min-width: 1160px) {
        padding-bottom: calc(100vh - 140px);
      }
    }
  }
  &__txt {
    @media (min-width: 1160px) {
      bottom: 60px;
    }
  }
}
.top-mv-carousel-pagenation {
  @media (min-width: 1160px) {
    column-gap: 16px;
    height: 60px;
  }
  .swiper-pagination-bullet {
    @media (min-width: 1160px) {
      width: 12px;
      height: 12px;
      background: #abd5d9;
    }
    &.swiper-pagination-bullet-active {
      @media (min-width: 1160px) {
        width: 20px;
        height: 20px;
        background: #2cac99;
      }
    }
  }
}
[data-role='top-mv-swiper'] {
  [class^='top-mv-carousel-nav'] {
    @media (min-width: 1160px) {
      top: 50%;
      bottom: auto;
      width: 32px;
      height: 48px;
      background: rgba(#fff, 0.8);
      box-shadow: 0 3px 6px rgba(#000, 0.16);
      color: #2cac99;
    }
  }
}
.top-mv-contents {
  display: none;
  @media (min-width: 1160px) {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    flex-shrink: 0;
    padding-bottom: 50px;
    background: #00a29a;
  }
  &__logo {
    @media (min-width: 1160px) {
      width: 224px;
      margin: 0 auto 17vh;
      text-align: center;
    }
  }
  &__search {
    @media (min-width: 1160px) {
      width: min(75.89%, 400px);
      position: absolute;
      top: 50%;
    }
    .gsc-control-cse {
      @media (min-width: 1160px) {
        padding: 0;
        border: none;
        border-radius: 50rem;
        box-shadow: 0 3px 6px rgba(#000, 0.16);
      }
    }
    form.gsc-search-box {
      margin: 0;
    }
    table.gsc-search-box {
      margin: 0;
      td.gsc-input {
        padding-right: 0;
      }
    }
    .gsc-input-box {
      background: none;
      border: none;
    }
    .gsib_a {
      padding: 6px 0 6px 20px;
    }
    .gsc-search-button-v2 {
      width: 40px;
      height: 40px;
      margin: 0;
      padding: 0;
      background: none;
      border: none;
      border-radius: 50%;
      svg {
        fill: #d0d0d0;
      }
      &:hover {
        background: none;
        border: none;
      }
    }
  }
  &__link {
    @media (min-width: 1160px) {
      position: absolute;
      bottom: 0;
      left: 0;
      display: grid;
      place-items: center;
      width: 100%;
      height: 50px;
      background: #e4f4f5;
      color: #00a29a;
      font-size: 14px;
    }
    &:hover {
      color: #00a29a;
    }
  }
}
.top-mv-contents-sns {
  @media (min-width: 1160px) {
    margin-bottom: 2.5vh;
  }
  &__title {
    @media (min-width: 1160px) {
      margin-bottom: 8px;
      color: #fff;
      font-size: 14px;
      font-weight: bold;
      text-align: center;
    }
  }
}
.top-mv-contents-sns-list {
  @media (min-width: 1160px) {
    display: flex;
    justify-content: center;
    column-gap: 10px;
  }
  &__link {
    @media (min-width: 1160px) {
      display: grid;
      place-items: center;
      width: 28px;
      height: 28px;
      background: #fff;
      border-radius: 50%;
      color: #00a29a;
    }
    &:hover {
      @media (min-width: 1160px) {
        color: #00a29a;
      }
    }
  }
}

.top-mv-contents-lang {
  @media (min-width: 1160px) {
    position: relative;
    display: flex;
    width: 160px;
    height: 34px;
    margin: 0 auto;
    padding: 0;
    cursor: pointer;
  }
  &__disp {
    @media (min-width: 1160px) {
      position: relative;
      display: flex;
      font-size: 14px;
      line-height: 1;
      width: 100%;
      background-color: #fff;
      border: 1px $color-gray-800 solid;
      border-radius: 4px;
      color: $color-gray-800 !important;
    }
    &:hover {
      @media (hover: hover) {
        background-color: #fff;
        color: $color-gray-800 !important;
      }
    }
    &.is-active {
      & + .top-mv-contents-lang__list {
        @media (min-width: 1160px) {
          display: block;
        }
      }
    }
  }
  &__disp-globe-icon {
    @media (min-width: 1160px) {
      position: absolute;
      width: 18px;
      top: 50%;
      left: 6px;
      transform: translateY(-50%);
    }
  }
  &__disp-down-icon {
    @media (min-width: 1160px) {
      position: absolute;
      width: 12px;
      top: 50%;
      right: 6px;
      transform: translateY(-50%);
    }
  }
  &__disp-txt {
    @media (min-width: 1160px) {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  &__list {
    @media (min-width: 1160px) {
      display: none;
      position: absolute;
      top: 42px;
      width: 160px;
      list-style: none;
      padding: 0;
      margin: 0 0 10px 0;
      z-index: 1404;
    }
  }
  &__list-item {
    &:last-child {
      @media (min-width: 1160px) {
        margin-bottom: 10px;
      }
    }
  }
  &__list-item-link {
    @media (min-width: 1160px) {
      font-size: 14px;
      background-color: var(--mieprefg-color-gray-50);
      border-left: 1px solid var(--mieprefg-color-gray-300);
      border-right: 1px solid var(--mieprefg-color-gray-300);
      border-bottom: 1px solid var(--mieprefg-color-gray-300);
      text-decoration: none !important;
      color: var(--mieprefg-color-gray-600) !important;
      display: inline-block;
      padding: 6px;
      width: 100%;
      transition: all 0.3s ease;
    }
    &:hover {
      @media (min-width: 1160px) {
        background-color: var(--mieprefg-color-gray-200);
        color: var(--mieprefg-color-gray-600) !important;
        text-decoration: none !important;
      }
    }
  }
}

.top-mv-scrolldown-btn {
  display: none;
  @media (min-width: 1160px) {
    position: absolute;
    right: 0;
    bottom: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 8px;
    padding: 12px;
    background: rgba(#fff, 0.9);
    box-shadow: 0 3px 6px rgba(#000, 0.16);
    text-decoration: none;
    z-index: +2;
  }
  .txt {
    @media (min-width: 1160px) {
      color: #2cac99;
      font-size: 12px;
      font-weight: bold;
      writing-mode: vertical-rl;
    }
  }
  .arrow {
    @media (min-width: 1160px) {
      display: block;
      width: 4px;
      height: 24px;
      border-bottom: 1px #2cac99 solid;
      border-left: 1px #2cac99 solid;
      transform: skewY(-45deg);
    }
  }
  &:hover {
    text-decoration: none;
  }
}

// seasonal
//
.top-summary-articles {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas:
    'block1 block1'
    'block2 block3'
    'block4 block5';
  @include bs.media-breakpoint-up(lg) {
    gap: 1.5rem;
    grid-template-columns: 2fr repeat(2, 1fr);
    grid-template-areas:
      'block1 block2 block3'
      'block1 block4 block5';
  }
}
.top-summary-articles-contents {
  position: relative;
  display: block;
  margin-bottom: 0;
  color: $color-gray-800;
  font-size: 16px;
  text-decoration: none;
  &:hover {
    color: $color-gray-800;
    text-decoration: none;
  }
  &__img {
    position: relative;
    width: 100%;
    margin-bottom: 0.5rem;
    border-radius: 1rem;
    overflow: hidden;
    @include bs.media-breakpoint-up(lg) {
      border-radius: 1.5rem;
    }
    &::before {
      display: block;
      padding-bottom: 78.57%;
      content: '';
      @include bs.media-breakpoint-up(lg) {
        padding-bottom: 65.8%;
      }
      @include bs.media-breakpoint-up(xl) {
        padding-bottom: 68.77%;
      }
    }
    img {
      position: absolute;
      top: 0;
      left: 50%;
      width: auto;
      height: 100%;
      transition: transform 0.3s;
      transform: translateX(-50%) translate3d(0, 0, 0);
    }
  }
  &__caption {
    position: absolute;
    top: auto;
    bottom: 0;
    left: 0;
    display: grid;
    place-items: center;
    width: 100%;
    height: auto;
    margin-bottom: 0;
    padding: clamp(0.25rem, 0.625vw, 0.5rem) 0.5rem;
    background: linear-gradient(rgba(#020101, 0.3) 0%, rgba(#000, 0.6) 100%);
    color: #fff;
    line-height: 1.25;
    text-align: center;
    @include bs.media-breakpoint-up(lg) {
      min-height: 3.5rem;
    }
  }
  &__title {
    width: 100%;
    height: 3em;
    margin-bottom: 0;
    line-height: 1.5;
  }
  &__num {
    position: absolute;
    top: 1rem;
    left: 1rem;
    display: grid;
    place-items: center;
    width: clamp(1.5rem, 3.75vw, 3rem);
    height: clamp(1.5rem, 3.75vw, 3rem);
    background: rgba(#fff, 0.8);
    border-radius: clamp(0.25rem, 0.625vw, 0.5rem);
    color: $primary;
    font-size: clamp(1rem, 1.875vw, 1.5rem);
    font-weight: bold;
    line-height: 1;
  }
  @for $i from 1 through 5 {
    &:nth-of-type(#{$i}) {
      grid-area: block#{$i};
    }
  }
  &:nth-of-type(1) {
    .top-summary-articles-contents__img {
      &::before {
        padding-bottom: 82.76%;
      }
    }
  }
  &:hover {
    .top-summary-articles-contents__img {
      img {
        @media (hover: hover) {
          transform: translateX(-50%) scale(1.05);
        }
      }
    }
  }
}

// PR
//
.top-pr {
  .heading-2-1__txt {
    font-size: func.rem(20);
  }
}

// theme
//
.top-theme {
  background: $color-green-2 url(../../assets/images/top/bg_theme.webp) repeat left top;
  .heading-2-1 {
    color: #fff;
    &::before {
      color: #fff;
    }
  }
  .heading-2-1-lead {
    color: #fff;
  }
  .top-summary-articles-contents__title {
    color: #fff;
  }
}

// pickup
//
.top-pickup-item {
  &__title {
    height: (4 * 1.75) + em;
    @include bs.media-breakpoint-up(lg) {
      height: (3 * 1.75) + em;
    }
    & > span {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 4;
      overflow: hidden;
      @include bs.media-breakpoint-up(lg) {
        -webkit-line-clamp: 3;
      }
    }
  }
}

// event
//
.top-event {
  .card-title {
    height: (2 * 1.75) + em;
  }
}

// ranking
//
.top-ranking {
  &__num {
    position: absolute;
    top: 1rem;
    left: 1rem;
    display: grid;
    place-items: center;
    width: clamp(1.5rem, 3.75vw, 3rem);
    height: clamp(1.5rem, 3.75vw, 3rem);
    background: rgba(#fff, 0.8);
    border-radius: clamp(0.25rem, 0.625vw, 0.5rem);
    color: $primary;
    font-size: clamp(1rem, 1.875vw, 1.5rem);
    font-weight: bold;
    line-height: 1;
  }
  .top-summary-articles-contents__title {
    height: auto;
    margin-bottom: 0.5rem;
    font-weight: bold;
  }
}
[data-content-id^='ranking-'] {
  .top-ranking__item:first-of-type {
    .top-ranking__num {
      width: 3rem;
      height: 3rem;
      font-size: 1.5rem;
    }
  }
  &:not([data-content-id='ranking-report']) {
    .card-text {
      height: (2 * 1.75) + em;
    }
  }
}
[data-content-id='ranking-report'] {
  .card-title {
    height: (4 * 1.75) + em;
    @include bs.media-breakpoint-up(lg) {
      height: (3 * 1.75) + em;
    }
    & > span {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 4;
      overflow: hidden;
      @include bs.media-breakpoint-up(lg) {
        -webkit-line-clamp: 3;
      }
    }
  }
}

// map
//
.top-map {
  background: repeating-linear-gradient(to bottom, #438997, #438997 8px, #579aa4, #579aa4 12px);
}

// .top-area-map {
//   position: relative;
//   background-color: red;
//   width: 300px;
//   @include bs.media-breakpoint-up(md) {
//     width: 300px;
//   }
// }

.top-area-map-container {
  display: flex;
  justify-content: left;
}

.top-area-map {
  background-color: #eee;
  position: relative;
  width: 100%;

  @include bs.media-breakpoint-up(xl) {
    width: 86%; // [hard cord] 地図とカードの要素の高さが綺麗に見える指定
  }

  &__base-img {
    width: 100%;
    height: auto;
  }

  &__partial-img {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    z-index: 2;
    display: none;

    &[data-state='active'] {
      display: block;
    }
  }
}

.top-map-card-items {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.top-map-card-item {
  $_radius: 0.75rem;

  width: 100%;
  background-color: white;
  border-radius: $_radius;

  &__img {
    border-radius: $_radius $_radius 0 0;
    overflow: hidden;
    margin-bottom: 1rem;
  }

  &__title {
    display: inline-block;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    width: fit-content;
    margin: 0 auto;
    padding: 0.75rem 1.5rem;
    border-radius: $_radius 0 0 0;

    @at-root .top-map-card-item.--area_1 & {
      background: #d8278d;
      border-color: #d8278d;
      color: #fff;
    }

    @at-root .top-map-card-item.--area_2 & {
      background: #8d3add;
      border-color: #8d3add;
      color: #fff;
    }

    @at-root .top-map-card-item.--area_3 & {
      background: #14931d;
      border-color: #14931d;
      color: #fff;
    }

    @at-root .top-map-card-item.--area_4 & {
      background: #dd603a;
      border-color: #dd603a;
      color: #fff;
    }

    @at-root .top-map-card-item.--area_5 & {
      background: #135cc6;
      border-color: #135cc6;
      color: #fff;
    }
  }

  &__title-main {
    font-size: 1.25rem;
    font-weight: 700;
    margin: 0 0 0.25rem 0;
    line-height: 1;

    // @include bs.media-breakpoint-up(lg) {
    //   font-size: 1.5rem;
    // }
  }

  &__title-sub {
    font-size: 0.75rem;
    font-weight: 500;
    margin: 0;
    line-height: 1;
  }

  &__description {
    padding: 0 1rem 0.25rem;
    font-size: 0.8rem;

    @include bs.media-breakpoint-up(md) {
      padding: 0 2rem 0.25rem;
      font-size: 1rem;
    }
  }
}

.info-title {
  font-size: 1.125rem;
  font-weight: 600;
  color: $color-green-2;
  text-decoration: underline;
  line-height: 1.8;
}

.info-txt {
  font-size: 0.9rem;
  @include bs.media-breakpoint-up(lg) {
    font-size: 1rem;
  }
}

.info-day {
  font-size: 0.9rem;
  @include bs.media-breakpoint-up(lg) {
    font-size: 1rem;
  }
}

// SNS
//

.top-sns {
  display: flex;
  justify-content: center;
  column-gap: clamp(1.25rem, 3.75vw, 3rem);
  color: #fff;
  &__link {
    display: grid;
    place-items: center;
    width: clamp(2rem, 4.375vw, 3.5rem);
    height: clamp(2rem, 4.375vw, 3.5rem);
    border-radius: 50%;
    color: #fff;
    font-size: clamp(1rem, 2.5vw, 2rem);
    text-decoration: none;
    &--facebook {
      color: $color-facebook;
      font-size: clamp(2rem, 4.375vw, 3.5rem);
      &:hover {
        color: $color-facebook !important;
      }
    }
    &--twitter {
      background-color: $color-twitter;
    }
    &--instagram {
      background: linear-gradient(-45deg, #ffe081 0%, #f2339a 50%, #624ded 100%);
    }
    &--youtube {
      background-color: $color-youtube;
    }
    &:hover {
      color: inherit;
    }
  }
}

.top-sns-item {
  width: 3rem;
  height: 3rem;
  border-radius: 3rem;
  text-align: center;
  background-color: inherit;
  color: white;

  &--type_twitter {
    background-color: $color-twitter;
  }

  &--type_facebook {
    background-color: $color-facebook;
  }

  &--type_instagram {
    background-color: $color-instagram;
  }

  &--type_youtube {
    background-color: $color-youtube;
  }
}

// PR
.bnr-pr {
  display: block;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
  &::after {
    display: block;
    color: $color-gray-800;
    content: 'PR';
    font-size: 10px;
    text-align: center;
  }
}
