// CP
//

@use '../core/variables' as *;
@use 'bootstrap' as bs;

.cp {
  display: flex;
  align-items: center;
  width: 9.75rem;
  height: 3rem;
  margin-bottom: 1rem;
  padding: 3px;
  background: #fff;
  border: 1px $color-gray solid;
  color: $primary;
  font-size: 0.875rem;
  line-height: 1.25;
  @include bs.media-breakpoint-up(lg) {
    position: absolute;
    top: 1rem;
    right: 1.75rem;
    margin-bottom: 0;
  }
  &__img {
    width: 2.5rem;
    margin-right: 0.5rem;
  }
  &__txt {
    flex: 1 0 0;
  }
}

.heading-1-2.has-cp {
  @include bs.media-breakpoint-up(lg) {
    padding-right: 11.75rem;
  }
}

.campaign {
  padding: 0.5rem;
  border: 2px #fccbac solid;
}
