// Pagination
//

@use '../core/variables' as *;
@use '../core/functions' as func;

.pagination {
  display: flex;
  justify-content: center;
  column-gap: 1rem;
  .page {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    font-weight: bold;
    overflow: hidden;
    & > a {
      display: grid;
      place-items: center;
      width: 100%;
      height: 100%;
      background: $color-green-4;
      color: $color-gray-800;
      text-decoration: none;
    }
    &.current {
      display: grid;
      place-items: center;
      background: $color-green-2;
      color: #fff;
    }
  }
  .first,
  .prev,
  .next,
  .last {
    a {
      color: $color-link;
      font-weight: bold;
    }
  }
}
