@use '../core/variables' as *;
@use '../core/functions' as func;
@use 'bootstrap' as bs;

.bookingsite-img-list {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  column-gap: 8px;
  &__item {
    display: grid;
    place-items: center;
  }
}

.booking-form {
  &__fieldset {
    display: contents;
  }
  &__legend {
    display: contents;
    & > span {
      margin-bottom: 16px;
      @include bs.media-breakpoint-up(lg) {
        width: 100px;
      }
    }
  }
  &__input {
    margin-bottom: 16px;
    @include bs.media-breakpoint-up(lg) {
      width: calc(100% - 100px);
    }
    input {
      width: 100% !important;
      padding: 8px 16px;
      border: 1px $color-gray-400 solid;
      font-size: 16px;
      &:focus-visible {
        box-shadow: none;
        outline: 2px $color-green-2 solid;
      }
    }
    select {
      width: 100%;
      padding: 8px 16px;
      background: #fff;
      border: 1px $color-gray-400 solid;
      font-size: 16px;
    }
  }
}
.booking-form-contents {
  margin-bottom: clamp(48px, 5vw, 64px);
  &__block {
    @include bs.media-breakpoint-up(lg) {
      display: flex;
      align-items: center;
    }
  }
}

.input-parallel {
  display: flex;
  align-items: center;
  column-gap: 8px;
  &__form {
    flex: 1 0 0;
  }
  &__txt {
    flex-shrink: 0;
    width: fit-content;
  }
}

.booking-further-conditions {
  margin-bottom: clamp(48px, 5vw, 64px);
  &__fieldset {
    width: 100%;
  }
  &__legend {
    width: 100%;
    margin-bottom: clamp(16px, 1.875vw, 24px);
    text-align: center;
  }
}

.booking-further-conditions-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
  list-style: none;
  padding: 0;
}
.booking-further-conditions-list-item {
  width: 100%;
  &__btn {
    display: grid;
    place-items: center;
    width: 100%;
    padding: 8px;
    background: #fff;
    border: 1px $color-gray-400 solid;
    border-radius: 8px;
    transition: background 0.3s;
    &.is-active {
      background: $color-green-3;
    }
  }
}

[data-role='booking-submit'] {
  cursor: pointer;
}
