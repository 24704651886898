// plan-comparison
//

@use '../core/variables' as *;
@use '../core/functions' as func;

.plan-comparison {
  display: block;
  padding: 1em;
  background: #df7c8a;
  border-radius: 0.1em;
  color: #fff;
  text-align: center;
  text-decoration: none;
  &__title {
    margin-bottom: 0.5rem;
    font-size: func.rem(18);
    font-weight: bold;
    span {
      display: block;
      width: fit-content;
      margin: 0 auto 0.5rem;
      border-top: 1px #fff solid;
      border-bottom: 1px #fff solid;
      font-size: func.rem(14);
    }
  }
  &__img {
    margin-bottom: 0.5rem;
    padding: 0.5rem;
    background: #fff;
  }
  &__btn {
    padding: 0.75rem 1rem;
    background: $color-green-2;
    color: #fff;
    font-weight: bold;
    line-height: 1.2;
    transition: background 0.3s, color 0.3s;
  }
  &:hover {
    @media (hover: hover) {
      color: #fff;
      text-decoration: none;
    }
    .plan-comparison__btn {
      @media (hover: hover) {
        background: #fff;
        color: $color-green-2;
      }
    }
  }
}
