// hayawakari
//

@use '../core/variables' as *;
@use '../core/functions' as func;
@use 'bootstrap' as bs;
@use 'sass:math';

// header
//
.hayawakari-header {
  margin-bottom: 5rem;
  background: url('../../assets/images/common/bg_pattern01.webp') repeat left top;
  &__title {
    margin: 0 0 1.5rem;
    padding: 1.5rem 1rem;
    color: #fff;
    border-bottom: clamp(2px, 0.3125vw, 4px) #fff solid;
    font-size: clamp(1.5rem, 3.125vw, 2.5rem);
    font-weight: bold;
    text-align: center;
  }
}
.hayawakari-contents-mv {
  position: relative;
  padding-bottom: 1.5rem;
  &__img {
    height: 240px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__title {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    margin: 0;
    color: #fff;
    font-size: clamp(1.5rem, 3.125vw, 2.5rem);
    font-weight: bold;
    text-align: center;
    transform: translateY(-50%);
  }
}

// mv
//
[data-role='hayawakari-swiper'] {
  margin-bottom: 2rem;
}
.hayawakari-swiper-slide {
  position: relative;
  display: block;
  &__img {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: clamp(14rem, 33.56%, 100%);
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__question {
    position: absolute;
    top: 36%;
    left: 0;
    width: 100%;
    margin-bottom: 0;
    color: #fff;
    font-size: clamp(1.5rem, 3.125vw, 2.5rem);
    font-weight: bold;
    text-align: center;
  }
  &__answer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    margin-bottom: 0;
    background: rgba($color-gray-900, 0.5);
    color: #fff;
    padding: 1rem 4rem 1rem 1.5rem;
    font-size: clamp(1rem, 1.56vw, 1.25rem);
    font-weight: bold;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    @include bs.media-breakpoint-up(lg) {
      padding-right: clamp(11rem, 23.125vw, 18.5rem);
    }
  }
  &__button {
    position: absolute;
    right: 1rem;
    bottom: 0.5rem;
    display: grid;
    place-items: center;
    width: 3rem;
    height: 3rem;
    background: $color-red;
    border-radius: 0.25rem;
    color: #fff;
    @include bs.media-breakpoint-up(lg) {
      width: clamp(10rem, 21.875vw, 17.5rem);
    }
    .txt {
      display: none;
      @include bs.media-breakpoint-up(lg) {
        display: block;
      }
    }
  }
}

// hayawakari-list
//
.hayawakari-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(343px, 1fr));
  column-gap: 1.5rem;
  counter-reset: chapter;
  margin-bottom: clamp(5rem, 11.25vw, 9rem);
}
.hayawakari-list-item {
  margin-bottom: 1.25rem;
  border: 1px $color-silver solid;
  @supports (grid-template-rows: subgrid) {
    display: grid;
    grid-row: span 5;
    grid-template-rows: subgrid;
  }
  &__img {
    position: relative;
    display: block;
    overflow: hidden;
    &::after {
      position: absolute;
      left: 50%;
      bottom: -4em;
      width: 8.5em;
      height: 7rem;
      padding-top: 0.5rem;
      counter-increment: chapter;
      content: 'Chapter ' counter(chapter);
      background: $color-gold;
      border-radius: 100%;
      color: $color-brown;
      font-size: func.rem(14);
      font-weight: bold;
      line-height: 3.5em;
      text-align: center;
      transform: translateX(-50%);
      z-index: 1;
      @include bs.media-breakpoint-up(lg) {
        background: $color-brown;
        color: #fff;
      }
    }
  }
  &__title {
    display: block;
    margin-bottom: 1rem;
    padding: 0.75rem 1rem;
    background: $color-green;
    color: #fff;
    font-size: func.rem(18);
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    &:hover {
      color: #fff;
      text-decoration: none;
    }
  }
  &__description {
    margin: 0 1rem 1rem;
  }
  &__button {
    width: calc(100% - 2rem);
    max-width: 280px;
    margin: 0 auto 2rem;
  }
}

// hayawakari-area
//
.hayawakari-area {
  position: relative;
  padding: clamp(1.5rem, 3.125vw, 2.5rem) 0.75rem 6rem;
  background: url('../../assets/images/common/bg_pattern01.webp') repeat left top;
  &__title {
    margin-bottom: clamp(1.5rem, 3.125vw, 2.5rem);
    color: #fff;
    font-size: clamp(1.5rem, 3.125vw, 2.5rem);
    font-weight: bold;
    text-align: center;
  }
  &::before {
    position: absolute;
    top: -3em;
    left: 50%;
    width: 8.5em;
    height: 7rem;
    content: 'Chapter 7';
    background: $color-brown;
    border-radius: 100%;
    color: #fff;
    font-size: func.rem(14);
    font-weight: bold;
    line-height: 3.5em;
    text-align: center;
    transform: translateX(-50%);
    z-index: -1;
  }
}
.hayawakari-area-body {
  padding: 0;
  @include bs.media-breakpoint-up(lg) {
    display: flex;
    height: 48.83vw;
    max-height: 584px;
    background: #fff;
  }
  &__map {
    position: relative;
    margin-bottom: 1.5rem;
    @include bs.media-breakpoint-up(lg) {
      width: 31%;
      margin-bottom: 0;
    }
  }
  &__contents {
    @include bs.media-breakpoint-up(lg) {
      width: 69%;
      height: 100%;
      overflow-y: auto;
    }
    &::-webkit-scrollbar {
      @include bs.media-breakpoint-up(lg) {
        width: 10px;
        border: 1px $color-silver solid;
      }
    }
    &::-webkit-scrollbar-track {
      @include bs.media-breakpoint-up(lg) {
        box-shadow: 0 3px 6px rgba(#333, 0.16) inset;
      }
    }
    &::-webkit-scrollbar-thumb {
      @include bs.media-breakpoint-up(lg) {
        background: $color-silver;
        border-radius: 2px;
      }
    }
  }
}
.hayawakari-area-button {
  position: absolute;
  width: 120px;
  .btn-more {
    min-height: 2.5rem;
    padding-inline: 1rem;
    .icon-pos-r {
      right: 0.75rem;
    }
  }
  $base_width: 372;
  $base_height: 578;
  &.--area_hokusei {
    top: math.div(29, $base_height) * 100%;
    left: math.div(75, $base_width) * 100%;
  }
  &.--area_iga {
    top: math.div(162, $base_height) * 100%;
    left: math.div(6, $base_width) * 100%;
  }
  &.--area_chunansei {
    top: math.div(270, $base_height) * 100%;
    left: math.div(31, $base_width) * 100%;
  }
  &.--area_iseshima {
    top: math.div(347, $base_height) * 100%;
    left: math.div(75, $base_width) * 100%;
  }
  &.--area_higashikishu {
    top: math.div(487, $base_height) * 100%;
    left: math.div(15, $base_width) * 100%;
  }
}
.hayawakari-area-list-item {
  display: flex;
  align-items: start;
  width: 100%;
  background: #fff;
  color: $color-gray-800;
  text-decoration: none;
  transition: background 0.3s;
  @include bs.media-breakpoint-up(lg) {
    align-items: stretch;
    padding: 0 0.25rem;
    background: none;
  }
  &:hover {
    @media (hover: hover) {
      text-decoration: none;
      background: $color-green-4;
      color: $color-gray-800;
    }
  }
  & + & {
    margin-top: 2px;
  }
  &__img {
    flex-shrink: 0;
    width: 30%;
    padding: 1rem;
    padding-right: 0;
    @include bs.media-breakpoint-up(lg) {
      width: 20%;
      padding: 0;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      @include bs.media-breakpoint-up(lg) {
        object-fit: cover;
      }
    }
  }
  &__contents {
    flex: 1 0 0;
    padding: 1rem;
    border-bottom: 1px $color-silver solid;
    @include bs.media-breakpoint-up(lg) {
      padding: 1.5rem 2rem;
    }
  }
}

[data-url-path='/hayawakari'] {
  .square-num-list {
    &.--type_gold {
      .square-num-list__item::before {
        background: $color-brown;
        color: #fff;
        @include bs.media-breakpoint-up(lg) {
          background: $color-gold;
          color: $color-brown;
        }
      }
    }
  }
}
