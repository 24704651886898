// topics
//

@use '../core/variables' as *;
@use '../core/functions' as func;
@use 'bootstrap' as bs;

// テーマカラーによる変更
@each $theme-name, $theme-info in $topics-theme-colors {
  [data-post-theme='topics-theme_#{$theme-name}'] {
    .topics-contents-body__title {
      background: map-get($theme-info, base_color);
    }
    .btn-more-2 {
      background: map-get($theme-info, btn_bg);
      color: map-get($theme-info, base_color);
      &::before {
        background: map-get($theme-info, base_color);
      }
      &:hover {
        @media (hover: hover) {
          background: map-get($theme-info, base_color);
          color: #fff;
        }
        &::before {
          @media (hover: hover) {
            background: #fff;
          }
        }
      }
    }
    .topics-contents-section__title {
      background: map-get($theme-info, base_color);
      border-image-source: linear-gradient(map-get($theme-info, base_color), map-get($theme-info, base_color));
    }
    .topics-column__title {
      border-color: map-get($theme-info, base_color);
      color: map-get($theme-info, base_color);
    }
    .topics-column-contents {
      border-color: map-get($theme-info, base_color);
    }
  }
}

.topics-contents-header {
  position: relative;
  margin-bottom: 2rem;

  @include bs.media-breakpoint-up(lg) {
    margin-bottom: 2.5rem;
  }

  &__bg {
    filter: blur(2px) opacity(60%);
    position: absolute;
    inset: 0;
    z-index: -1;
  }

  &__inner {
    aspect-ratio: 1 / 1;
    position: relative;
    margin: 0 auto;

    @include bs.media-breakpoint-up(sm) {
      aspect-ratio: 1.6 / 1;
    }

    @include bs.media-breakpoint-up(xl) {
      height: calc(100vh - 208px);
    }
  }

  &__title {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    width: calc(100% - 24px);
    margin: 0 auto;
    font-size: 1.2rem;

    @include bs.media-breakpoint-up(lg) {
      font-size: 1.5rem;
    }
  }

  &__img {
    width: 100%;
    height: 100%;
  }
}

.topics-contents-body {
  width: calc(100% - 24px);
  max-width: 888px;
  margin-inline: auto;
}

.topics-contents-section {
  margin-bottom: 5rem;
  &__title {
    margin: 0 0 1rem;
    border-image-source: linear-gradient($color-green-2, $color-green-2);
    border-image-slice: 0 fill;
    border-image-outset: 0 0 0 100vw;
    border-image-width: calc(50% - 2px) 0;
  }
}

.topics-single-swiper {
  .ratio {
    position: relative;
    overflow: hidden;
    img {
      position: absolute;
      top: 0;
      left: 50%;
      width: auto;
      height: 100%;
      transform: translateX(-50%);
    }
  }
}

.topics-contents-link-wrap {
  width: 100%;
  max-width: 584px;
  margin-inline: auto;
}

.topics-spot-block {
  border-bottom: 1px $color-silver solid;
  margin-bottom: 1.5rem;
  @include bs.media-breakpoint-up(lg) {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2.5rem;
  }
  &:nth-of-type(2n) {
    flex-direction: row-reverse;
  }
  &__img {
    @include bs.media-breakpoint-up(lg) {
      width: 42.9%;
      margin-bottom: 3rem;
    }
    img {
      width: auto;
      height: 100%;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  &__contents {
    @include bs.media-breakpoint-up(lg) {
      width: 54.28%;
      margin-bottom: 1.5rem;
    }
  }
}

.topics-column {
  margin-bottom: 3.5rem;
  &__title {
    position: relative;
    display: flex;
    align-items: baseline;
    width: fit-content;
    margin: 0 0 0 2rem;
    padding: 0.75rem 1.5rem;
    background: #fff;
    border: 1px $color-green-2 solid;
    color: $color-green-2;
    line-height: 1;
    z-index: +1;
    &::before {
      content: 'column';
      font-family: 'montserrat_bold';
      font-size: func.rem(27);
      speak: none;
      text-transform: uppercase;
    }
    span {
      &::before {
        content: '／';
      }
    }
  }
}
.topics-column-contents {
  padding: 2.5rem 1.5rem 1.5rem;
  border: 1px $color-green-2 solid;
  transform: translateY(-1.5rem);
  @include bs.media-breakpoint-up(lg) {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 4rem 2rem 2rem;
  }
  &__img {
    @include bs.media-breakpoint-up(lg) {
      width: 52.46%;
    }
  }
  &__block {
    @include bs.media-breakpoint-up(lg) {
      width: 41.02%;
    }
  }
  [data-role='single-swiper'] {
    padding-bottom: clamp(2rem, 5vw, 4rem);
    .swiper-pagination {
      bottom: 0;
    }
    .ratio {
      position: relative;
      overflow: hidden;
      img {
        position: absolute;
        top: 0;
        left: 50%;
        width: auto;
        height: 100%;
        transform: translateX(-50%);
      }
    }
  }
}

.topics-contents-footer {
  padding: clamp(32px, 5.3125vw, 68px) 0 clamp(32px, 6.41vw, 82px);
  background: $color-green-4;
}
.topics-author {
  width: calc(100% - 24px);
  max-width: 888px;
  margin-inline: auto;
  @include bs.media-breakpoint-up(lg) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__img {
    margin-bottom: 1.5rem;
    @include bs.media-breakpoint-up(lg) {
      width: 25.9%;
      margin-bottom: 0;
    }
  }
  &__contents {
    padding: 1rem 1.5rem;
    background: #fff;
    @include bs.media-breakpoint-up(lg) {
      width: 71.4%;
    }
  }
}

.topics-aside {
  width: calc(100% - 24px);
  max-width: 888px;
  margin-inline: auto;
}
.topics-aside-swiper-item {
  .ratio {
    img {
      width: auto;
      height: 100%;
      left: 50%;
      transform: translateX(-50%) translate3d(0, 0, 0);
    }
  }
}
