// Archive Filter
//
@use '../core/variables' as *;
@use '../core/functions' as func;
@use 'bootstrap' as bs;

.archive-filter {
  &__keyword {
    width: 100%;
    display: inline-block;
    border: 1px $color-gray-800 solid;
    border-radius: 0.25rem;
    input[type='search'] {
      width: 100%;
      padding: 0.75rem 1rem;
      background: none;
      border: none;
      border-radius: 0.25rem;
      color: $color-gray-800;
      line-height: 1.5;
      &::placeholder {
        color: $color-silver;
      }
    }
  }

  &__content-wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    margin: 0;
    padding: 1rem 1rem 2rem 2rem;
    background-color: #fff;
    border: 1px $color-silver solid;
    border-radius: 0.25rem;
    box-shadow: 0 3px 6px rgba(#000, 0.16);
    z-index: 100;
  }

  &__content-closer {
    position: absolute;
    right: 0;
    top: 0;
    width: 2rem;
    height: 2rem;
    padding: 0;
    background-color: #fff;
    border: none;
    color: $color-gray-800;
    font-size: func.rem(20);
    z-index: 2;
  }

  &__content {
    max-height: calc(640px - 4rem);
    padding-right: 1rem;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar-track {
      border: 1px $color-silver solid;
      border-radius: 2px;
      box-shadow: 0 3px 6px rgba(#000, 0.16) inset, 0 -3px -6px rgba(#000, 0.16) inset;
    }
    &::-webkit-scrollbar-thumb {
      background: $color-silver;
      border-radius: 2px;
    }
  }

  &__content-heading {
    margin-bottom: 1rem;
    padding: 0.1rem;
    background-color: $color-green-4;
    color: $color-green-2;
    font-size: func.rem(16);
    font-weight: bold;
    text-align: center;
  }

  &__input-date {
    position: relative;
    padding: 0.25rem 2.5rem 0.25rem 1rem;
    border: 1px $color-gray-800 solid;
    border-radius: 50rem;
    input[type='date'] {
      padding: 0;
      border: none;
      @include bs.media-breakpoint-up(md) {
        width: auto !important;
      }
      &::-webkit-calendar-picker-indicator {
        // display: none;
        // ↓イベントの日付検索のため仮入力しました(BE)
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        padding: 0;
        background: transparent;
        color: transparent;
        height: 100%;
        width: 100%;
        // ここまで
      }
    }
  }

  &__area-map-list-btn {
    width: 100%;
    margin-bottom: 0.5rem;
    padding: 0.5rem;
    background: #fff;
    border: 1px $color-gray-800 solid;
    border-radius: 0.25rem;
    color: $color-gray-800;
    line-height: 1.5;
    transition: background 0.3s, color 0.3s;

    &:hover {
      @media (hover: hover) {
        background: $color-green-4;
      }
    }

    &[data-state='active'] {
      background: $color-green-2;
      color: #fff;
    }

    &.--area_1 {
      &[data-state='active'] {
        background: #d8278d;
        border-color: #d8278d;
        color: #fff;
      }
    }

    &.--area_2 {
      &[data-state='active'] {
        background: #8d3add;
        border-color: #8d3add;
        color: #fff;
      }
    }

    &.--area_3 {
      &[data-state='active'] {
        background: #14931d;
        border-color: #14931d;
        color: #fff;
      }
    }

    &.--area_4 {
      &[data-state='active'] {
        background: #dd603a;
        border-color: #dd603a;
        color: #fff;
      }
    }

    &.--area_5 {
      &[data-state='active'] {
        background: #135cc6;
        border-color: #135cc6;
        color: #fff;
      }
    }
  }

  &__area-map-list-txt {
    font-size: 0.8rem;
    line-height: 1.5;
  }
}

.archive-filter-area-list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  column-gap: 1rem;
  &__item {
    width: 100%;
    margin-bottom: 1.5rem;
    padding-bottom: 1.5rem;
    border-bottom: 1px $color-silver dotted;
    @include bs.media-breakpoint-up(lg) {
      flex: 1 0 0;
      border-bottom: none;
      padding-bottom: 0;
    }
  }
  &__area-name {
    color: $color-gray-800;
    font-weight: bold;
  }
}
.archive-filter-area-municipality-list {
  margin: 0 0 0 1rem;
  padding: 0;
  list-style: none;
  &__item {
    line-height: 1.5;
    input {
      margin-right: 0.25rem;
    }
  }
  &__item + &__item {
    margin-top: 0.5rem;
  }
}

.archive-filter-area-map {
  background-color: #eee;
  position: relative;

  &__base-img {
    width: 100%;
    height: auto;
    // position: absolute;
    // left: 0;
    // right: 0;
    // top: 0;
    // bottom: 0;
    // width: 100%;
    // z-index: 1;
  }

  &__partial-img {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    z-index: 2;
    display: none;

    &[data-state='active'] {
      display: block;
    }
  }
}
