// pamphlet
//

@use '../core/variables' as *;
@use '../core/functions' as func;
@use 'bootstrap' as bs;

.pamphlet-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(343px, 1fr));
  column-gap: 1.5rem;
}
.pamphlet-list-item {
  margin-bottom: clamp(2.5rem, 6.25vw, 5rem);
  @supports (grid-template-rows: subgrid) {
    display: grid;
    grid-row: span 4;
    grid-template-rows: subgrid;
  }
  &__title {
    margin-bottom: 1.5rem;
    color: $color-green-2;
    font-size: func.rem(20);
    font-weight: bold;
  }
  &__img {
    height: 15rem;
    margin-bottom: 2rem;
    background: $color-gray-100;
    border: 1px $color-silver solid;
  }
}
