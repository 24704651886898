// season
//

@use '../core/variables' as *;
@use '../core/functions' as func;
@use 'bootstrap' as bs;

.swiper--report-index {
  .swiper-wrapper {
    padding-bottom: 1px;
    padding-left: calc(24% + 12px);
    @include bs.media-breakpoint-up(md) {
      padding-left: calc(9.2% + 12px);
    }
    @include bs.media-breakpoint-up(lg) {
      padding-left: calc(7.2% + 12px);
    }
  }
}
