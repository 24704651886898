// Base
//
@use '../core/variables' as *;
@use 'bootstrap' as bs;
@use 'sass:math';

html {
  scroll-behavior: smooth;
  scroll-padding-top: 48px;
  @include bs.media-breakpoint-up(lg) {
    scroll-padding-top: 152px;
  }
}

body {
  overscroll-behavior: contain;
  color: $color-gray-800;
  position: relative;
}

a {
  line-height: 1.75;
}

// [todo]
// tmpページを見やすくするためだけのパーツ。
// 不要になったら削除すること
//
.heading-for-tmp {
  margin: 1.2rem 0 2rem;
  background-color: purple;
  color: white;
  padding: 0.25rem 1rem;
}
