// course
//

@use '../core/variables' as *;
@use '../core/functions' as func;
@use 'bootstrap' as bs;

.concierge-card {
  $_icon: & + '__icon';

  border-radius: 10px;
  overflow: hidden;
  position: relative;
  height: 30vw;
  cursor: pointer;

  @include bs.media-breakpoint-up(md) {
    height: 14vw;
  }

  &.is-selected {
    border: solid 2px bs.$primary;

    > #{$_icon} {
      display: grid;
    }
  }

  &__icon {
    background-color: bs.$primary;
    border-radius: 50%;
    color: bs.$white;
    display: none;
    place-items: center;
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
    width: 2rem;
    height: 2rem;
  }

  &__img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  &__ttl {
    background: linear-gradient(to top, rgba(bs.$black, 0.8), rgba(bs.$black, 0));
    color: bs.$white;
    font-size: 0.9rem;
    text-align: center;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    padding: 0.25rem 0 0.5rem;
    margin: 0;
  }
}

.concierge-which-slider {
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  height: 14px;
  width: 100%;
  border-radius: 10px;
  border: solid 1px #d3d3d3;
  outline: 0;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    background: bs.$primary;
    width: 10px;
    height: 30px;
    border: none;
  }

  &::-moz-focus-outer {
    border: 0;
  }
}

.concierge-slider {
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  height: 14px;
  width: 100%;
  border-radius: 10px;
  border: solid 1px #d3d3d3;
  outline: 0;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    background: bs.$primary;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: none;
  }

  &::-moz-range-thumb {
    background: bs.$primary;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: none;
  }

  &::-moz-focus-outer {
    border: 0;
  }
}
