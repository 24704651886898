// Table
//
@use '../core/variables' as *;
@use '../core/functions' as func;

.table-colon-separated {
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  &__title {
    position: relative;
    flex-shrink: 0;
    width: clamp(9rem, 17.4vw, 11rem);
    margin: 0;
    padding: 0.5rem 1rem 0.5rem 0;
    border-bottom: 1px $color-silver solid;
    font-weight: normal;
    &::after {
      margin-left: 0.25rem;
      content: ':';
    }
  }
  &__contents {
    flex-grow: 1;
    margin: 0;
    padding: 0.5rem;
    border-bottom: 1px $color-silver solid;
  }
}

.table-cmn {
  border: 1px $color-silver solid;
  width: 100%;

  &__tr {
    border: 1px $color-silver solid;
  }

  &__th {
    width: 30%;
    padding: 0.5rem 1rem;
    background: $color-gray-100;
    border: 1px $color-silver solid;
  }

  &__td {
    padding: 0.5rem 1rem;
    border: 1px $color-silver solid;
  }
}

.table-list {
  background-color: #eee;
  border: 1px solid black;
  width: 100%;

  &__tr {
    border: 1px solid $color-gray-400;
  }

  &__th {
    padding: 0.25rem;
    border: 1px solid $color-gray-400;
  }

  &__td {
    padding: 0.25rem;
    border: 1px solid $color-gray-400;
    background-color: #fff;
  }
}

.table-scroll-wrap {
  overflow-x: auto;
  & > table {
    white-space: nowrap;
  }
}
