// note
//

@use '../core/variables' as *;

.note-cmn {
  background-color: $color-gray-100;

  &__ttl {
    font-weight: 700;
    color: $color-gray-800;
    font-size: 1.125rem;
  }

  &__txt {
    color: $color-gray-800;
  }
}
