// tab
//
@use '../core/variables' as *;
@use 'bootstrap' as bs;

.tab-cmn-list {
  display: flex;
  column-gap: clamp(0.75rem, 1.875vw, 1.5rem);
  padding-inline: clamp(0.75rem, 3.4375vw, 2.75rem);
  overflow-x: auto;
  box-shadow: 0 -2px 0 $color-green-2 inset;
}

.tab-cmn-list-item {
  flex-shrink: 0;
  width: clamp(6rem, 21.875vw, 17.5rem);
  min-height: 3rem;
  background: $color-green-4;
  border: 2px $color-green-2 solid;
  border-radius: 0.5rem 0.5rem 0 0;
  color: $color-green-2;
  text-decoration: none;
  transition: background 0.3s;
  font-size: 0.8rem;

  @include bs.media-breakpoint-up(md) {
    font-size: 1rem;
  }

  &:hover {
    @media (hover: hover) {
      background: $color-green-3;
      text-decoration: none;
    }
  }

  &[data-state='active'] {
    background-color: #fff;
    border-bottom-color: #fff;
    text-decoration: none;
  }
}

[data-role='tabpanel'] {
  display: none;
  &[data-state='active'] {
    display: block;
  }
}

.side-content-tab {
  display: flex;
  column-gap: 12px;
  margin-bottom: 8px;
  border-bottom: 5px $color-green-2 solid;
  &__item {
    padding-block: 8px;
    flex: 1 0 0;
    background: #fff;
    border: 1px $color-green-2 solid;
    border-bottom: none;
    border-radius: 8px 8px 0 0;
    color: $color-green-2;
    font-size: 14px;
    text-align: center;
    transition: background 0.3s, color 0.3s;
    @include bs.media-breakpoint-up(lg) {
      font-size: 12px;
    }
    @include bs.media-breakpoint-up(xl) {
      font-size: 14px;
    }
    &:hover {
      @media (hover: hover) {
        background: $color-green-2;
        color: #fff;
      }
    }
    &[data-state='active'] {
      background: $color-green-2;
      color: #fff;
    }
  }
}

.sidebar-container {
  @include bs.media-breakpoint-up(lg) {
    position: sticky;
    align-self: flex-end;
    bottom: 20px;
  }
}