// Article Body
//
// CMS側でwysiwygで処理される要素は本ラッパーを起点とした子孫クラスでデザインする

.editor-wrap {
  word-break: break-all;
  a {
    text-decoration: none;
  }
  img:not(.object-fit-contain-center) {
    width: 100%;
    height: auto;
  }
  iframe[src^='https://www.google.com/maps']
  {
    width: 100% !important;
    height: auto !important;
    aspect-ratio: 16/9 !important;
  }
  @for $i from 1 through 10 {
    table[border='#{$i}'] {
      border-width: #{$i}px;
      th,
      td {
        border-width: #{$i}px;
      }
    }
  }
}

div[id*='column'] p:last-child {
  display: block !important;
}
