// Archive Status
//

@use '../core/variables' as *;

.archive-status {
  padding: 1rem 0;
  border-top: 1px $color-silver solid;
  border-bottom: 1px $color-silver solid;
  font-weight: bold;
  text-align: center;
}
