// myplan-balloon
//

@use '../core/variables' as *;
@use '../core/mixins' as mix;
@use '../core/functions' as func;
@use 'bootstrap' as bs;

.myplan-balloon {
  position: absolute;
  right: 0;
  bottom: 100%;
  left: 0;
  width: clamp(1px, 100%, 400px);
  margin-inline: auto;
  padding: 2.5rem;
  background: #fff;
  border: 1px #d0d0d0 solid;
  border-radius: 0.25rem;
  box-shadow: 0 3px 6px rgba(#000, 0.16);
  text-align: center;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.5s, bottom 0.5s;
  @include bs.media-breakpoint-up(md) {
    right: -28px;
    left: -28px;
    width: auto;
  }
  @include bs.media-breakpoint-up(lg) {
    right: -90px;
    left: -90px;
  }
  @include bs.media-breakpoint-up(xl) {
    right: -60px;
    left: -60px;
  }
  &::before {
    position: absolute;
    top: calc(100% - 9px);
    left: 50%;
    width: 20px;
    height: 20px;
    background: #d0d0d0;
    box-shadow: 3px 3px 6px rgba(#000, 0.16);
    content: '';
    transform: translateX(-50%) rotate(45deg) skew(20deg, 20deg);
    z-index: -1;
  }
  &::after {
    position: absolute;
    top: calc(100% - 10px);
    left: 50%;
    width: 20px;
    height: 20px;
    background: #fff;
    content: '';
    transform: translateX(-50%) rotate(45deg) skew(20deg, 20deg);
  }
  &__num {
    padding-inline: 0.5rem;
    color: $color-green-2;
    font-size: 1.5rem;
    font-weight: bold;
  }
  &__close {
    position: absolute;
    top: 0;
    right: 0;
    display: grid;
    place-items: center;
    width: 2rem;
    height: 2rem;
    padding: 0;
    background: $color-green-2;
    border: none;
    border-radius: 0 0.25rem 0 0;
    color: #fff;
  }
  &.is-show {
    bottom: calc(100% + 1.5rem);
    opacity: 1;
    pointer-events: auto;
  }
}
