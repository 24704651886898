/*
  * Global Header
  *
  * [本ソースの転用先]
  * - 観光三重公式サイト
  * - 一部の観光三重特設サイト
  * - 外部サービス「たびくら」
  *
  * [記述条件]
  * - cssを記載する（scssを用いない)
  * - デザインクラス名にmiepref- というプレフィクスを用いる
  * - css変数にはmieprefg- というプレフィクスを用いる
  *  - 本家サイトでBootstrapが内部で用いるmiepref-との衝突を避けるためgをつける
  * - Bootstrapのような外部フレームワークを用いない
  * - 導入先サイトによって条件が異なる可能性があるためremは利用しない
  *
*/

:root {
  --mieprefg-header-pc-main-h: 92px;
  --mieprefg-header-pc-sub-h: 48px;
  --mieprefg-header-pc-h: auto; /* 140px */
  --mieprefg-header-sp-h: 64px;
  --mieprefg-header-side-gutter: 24px;
  --mieprefg-logo-pc-size: clamp(176px, 20vw, 260px);
  --mieprefg-logo-and-menu-pc-gutter: 48px;
  --mieprefg-color-green-1: #2cac99;
  --mieprefg-color-green-2: #1b8c98;
  --mieprefg-color-green-3: #abd5d9;
  --mieprefg-color-green-4: #e4f4f5;
  --mieprefg-color-gray-50: #fafafa;
  --mieprefg-color-gray-200: #e5e5e5;
  --mieprefg-color-gray-300: #d4d4d4;
  --mieprefg-color-gray-600: #525252;
  --mieprefg-color-gray-800: #333333;
  --mieprefg-color-gray-900: #050505;
  --mieprefg-color-yellow: #ffdb65;
}

/* --------------------------------
  Base & Shared
-------------------------------- */

.miepref-global-header {
  box-sizing: border-box;
  position: relative;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  overflow-y: auto;
  margin: 0;
  padding: 0;
  height: var(--mieprefg-header-sp-h);
  z-index: 1030;
  font-family: 'Noto Sans JP', 'noto_sans', sans-serif;
  background-color: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.4);
}
@media (min-width: 1160px) {
  .miepref-global-header {
    height: var(--mieprefg-header-pc-h);
    background-color: white;
    overflow-y: initial;
  }
}
.miepref-global-header[data-state='sp-open'] {
  height: 100%;
  background-color: var(--mieprefg-color-green-4);
  box-shadow: none;
}

.miepref-global-header-contents {
  display: none;
}
@media (min-width: 1160px) {
  .miepref-global-header-contents {
    display: block;
    position: relative;
  }
}
.miepref-global-header-contents[data-state='active'] {
  display: block;
  background-color: transparent;
  animation-name: miepref-global-header-contents-fade;
  animation-duration: 0.3s;
  animation-delay: 0;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
  opacity: 0;
}

@keyframes miepref-global-header-contents-fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.miepref-global-header-content-ttl {
  margin: 0 0 24px;
  padding: 0 0 16px;
  color: var(--mieprefg-color-green-2) !important;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  line-height: 1.2;
  border-bottom: 1px solid var(--mieprefg-color-green-2);
}
@media (min-width: 1160px) {
  .miepref-global-header-content-ttl {
    display: none;
  }
}

/* --------------------------------
  Toggler
-------------------------------- */

.miepref-global-header-toggler {
  position: absolute;
  top: 0;
  right: 0;
  width: 64px;
  height: var(--mieprefg-header-sp-h);
  padding: 0;
  background: none;
  border: none;
  background-color: var(--mieprefg-color-green-1);
}
@media (min-width: 1160px) {
  .miepref-global-header-toggler {
    display: none;
  }
}
.miepref-global-header-toggler[data-state='active'] {
  background-color: transparent;
}

.miepref-global-header-toggler > span {
  position: absolute;
  top: 31px;
  left: 18px;
  width: 28px;
  height: 2px;
  background: white;
  border-radius: 1px;
  transition: background 0.3s;
  &::before,
  &::after {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 2px;
    background: white;
    border-radius: 1px;
    content: '';
    transition: transform 0.3s;
  }
  &::before {
    margin-top: -12px;
  }
  &::after {
    margin-top: 10px;
  }
}

.miepref-global-header-toggler[data-state='active'] > span {
  background: transparent;
  &::before {
    margin-top: 0;
    transform: rotate(-135deg);
    background-color: var(--mieprefg-color-green-1);
  }
  &::after {
    margin-top: 0;
    transform: rotate(-45deg);
    background-color: var(--mieprefg-color-green-1);
  }
}

/* --------------------------------
  Logo
-------------------------------- */

.miepref-global-header-logo {
  margin: 10px 0 0 var(--mieprefg-header-side-gutter);
  padding: 0;
  width: 176px;
  height: auto;
  box-sizing: border-box;
  display: inline-block;
}
@media (min-width: 1160px) {
  .miepref-global-header-logo {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    width: var(--mieprefg-logo-pc-size);
    height: var(--mieprefg-header-pc-main-h);
    position: absolute;
    top: 0;
    left: var(--mieprefg-header-side-gutter);
    z-index: 2;
  }
}

.miepref-global-header-logo__link {
  width: 100%;
  line-height: 0.5;
  display: block;
}

.miepref-global-header-logo__img {
  width: 100%;
}

/* --------------------------------
  Main Container
-------------------------------- */

.miepref-global-header-main-container {
  box-sizing: border-box;
  margin: 32px auto 32px auto;
  padding: 0 12px;
  width: 100%;
}
@media (min-width: 1160px) {
  .miepref-global-header-main-container {
    margin: 0;
    padding: 0 var(--mieprefg-header-side-gutter);
    height: var(--mieprefg-header-pc-main-h);
    display: flex;
  }
}

@media (min-width: 1160px) {
  .miepref-global-header-menu {
    padding: 0 0 0 calc(var(--mieprefg-logo-pc-size) + var(--mieprefg-logo-and-menu-pc-gutter));
    height: 100%;
    width: 100%;
  }
}

.miepref-global-header-menu-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0 12px;
  list-style: none;
  height: 100%;
}
@media (min-width: 1160px) {
  .miepref-global-header-menu-list {
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding: 0 0 14px 0;
    align-items: flex-end;
  }
}

.miepref-global-header-menu-list-item {
  flex: 0 0 auto;
  width: 50%;
  margin: 0 0 16px 0;
}
@media (min-width: 1160px) {
  .miepref-global-header-menu-list-item {
    width: auto;
    padding: 0;
    margin: 0;
  }
}
.miepref-global-header-menu-list-item__link {
  text-decoration: none !important;
  color: var(--mieprefg-color-gray-800) !important;
  font-weight: 500;
  line-height: 1;
  font-size: 16px;
  transition: color 0.2s;
}
@media (min-width: 1160px) {
  .miepref-global-header-menu-list-item__link {
    display: flex;
    align-items: center;
  }
}
.miepref-global-header-menu-list-item__link.is-active,
.miepref-global-header-menu-list-item__link:hover {
  text-decoration: none !important;
  color: var(--mieprefg-color-green-1) !important;
  font-weight: 500;
}
.miepref-global-header-menu-list-item__link .icon {
  display: inline-block;
  width: 24px;
  margin-right: 4px;
}
@media (min-width: 1160px) {
  .miepref-global-header-menu-list-item__link .icon {
    width: auto;
  }
}
.miepref-global-header-menu-list-item__icon {
  height: 18px;
  color: var(--mieprefg-color-green-1) !important;
}

/* --------------------------------
  Util Container
-------------------------------- */

.miepref-global-header-util-container {
  width: 100%;
  box-sizing: border-box;
  padding: 0 12px;
  margin: 32px auto;
  text-align: left;
}
@media (min-width: 1160px) {
  .miepref-global-header-util-container {
    position: absolute;
    top: 12px;
    right: var(--mieprefg-header-side-gutter);
    margin: 0;
    padding: 0;
  }
}

@media (min-width: 1160px) {
  .miepref-global-header-utils {
    display: flex;
    justify-content: end;
  }
}

/* util - lang
----------------------------- */

.miepref-global-header-lang {
  position: relative;
  display: flex;
  height: 42px;
  margin: auto auto 16px auto;
  padding: 0;
  background-color: white;
  cursor: pointer;
  width: 100%;
}
@media (min-width: 1160px) {
  .miepref-global-header-lang {
    margin: 0;
    height: 34px;
    width: 160px;
  }
}
.miepref-global-header-lang__disp {
  position: relative;
  display: flex;
  font-size: 14px;
  line-height: 1;
  width: 100%;
  transition: all 0.3s ease;
  border: 1px solid var(--mieprefg-color-gray-800);
  border-radius: 4px;
  color: var(--mieprefg-color-gray-800) !important;
}
.miepref-global-header-lang__disp:hover {
  background-color: var(--mieprefg-color-gray-200);
  color: var(--mieprefg-color-gray-800) !important;
}

.miepref-global-header-lang__disp-globe-icon {
  position: absolute;
  width: 18px;
  top: 50%;
  left: 6px;
  transform: translateY(-50%);
}
.miepref-global-header-lang__disp-down-icon {
  position: absolute;
  width: 12px;
  top: 50%;
  right: 6px;
  transform: translateY(-50%);
}
.miepref-global-header-lang__disp-txt {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.miepref-global-header-lang__list {
  display: none;
  position: absolute;
  top: 42px;
  width: 100%;
  list-style: none;
  padding: 0 0 10px 0;
  margin: 0 0 10px 0;
  z-index: 1404;
}
@media (min-width: 1160px) {
  .miepref-global-header-lang__list {
    position: fixed;
    top: 42px;
    width: 160px;
    padding: 0;
  }
}
.miepref-global-header-lang__list[data-state='active'] {
  display: block;
}

.miepref-global-header-lang__list-item:last-child {
  margin-bottom: 10px;
}
.miepref-global-header-lang__list-item-link {
  font-size: 14px;
  background-color: var(--mieprefg-color-gray-50);
  border-left: 1px solid var(--mieprefg-color-gray-300);
  border-right: 1px solid var(--mieprefg-color-gray-300);
  border-bottom: 1px solid var(--mieprefg-color-gray-300);
  text-decoration: none !important;
  color: var(--mieprefg-color-gray-600) !important;
  display: inline-block;
  padding: 6px;
  width: 100%;
  transition: all 0.3s ease;
}
.miepref-global-header-lang__list-item-link:hover {
  background-color: var(--mieprefg-color-gray-200);
  color: var(--mieprefg-color-gray-600) !important;
  text-decoration: none !important;
}

/* util - g-search
----------------------------- */

.miepref-global-header-g-search {
  height: 42px;
  width: 100%;
  margin: auto auto 76px auto;
}
@media (min-width: 1160px) {
  .miepref-global-header-g-search {
    height: 34px;
    margin: 0 24px 0 0;
    padding: 0;
    width: 200px;
    box-sizing: border-box;
  }
}

.miepref-global-header-g-search .gsc-control-cse {
  padding: 0;
  margin: 0;
  border: none;
  background-color: transparent;
}

.miepref-global-header-g-search .gsc-search-box {
  margin: 0 !important;
}

.miepref-global-header-g-search .gsc-search-box tr {
  background: none !important;
}

.miepref-global-header-g-search .gsc-search-box .gsc-search-button {
  padding: 0 !important;
}

.miepref-global-header-g-search .gsc-input {
  margin: 0 !important;
  padding: 0 4px 0 0 !important;
  border: none;
}

@media (min-width: 1160px) {
  .miepref-global-header-g-search .gsc-input td {
    padding: 0 !important;
    box-sizing: border-box !important;
  }
}

@media (min-width: 1160px) {
  .miepref-global-header-g-search .gsc-input input {
    padding: 0 10px !important;
    font-size: 16px !important;
    line-height: 1 !important;
  }
}

.miepref-global-header-g-search .gscb_a {
  font-size: 16px !important;
}
@media (min-width: 1160px) {
  .miepref-global-header-g-search .gscb_a {
    line-height: 1 !important;
    font-size: 12px !important;
  }
}

.miepref-global-header-g-search .gsc-search-button-v2 {
  border: 0;
  height: 42px;
  width: 42px;
  margin: 0;
  padding: 0;
  margin: 0;
  background: var(--mieprefg-color-green-1);
  border: none;
  border-radius: 4px;
  color: white;
  transition: all 0.3s ease;
}
@media (min-width: 1160px) {
  .miepref-global-header-g-search .gsc-search-button-v2 {
    height: 34px;
    width: 34px;
  }
}

.miepref-global-header-g-search .gsc-search-button-v2:hover {
  background: var(--mieprefg-color-green-2);
}

.miepref-global-header-g-search #gsc-i-id1 {
  background-image: none !important;
}

/* util - myplan
----------------------------- */

.miepref-global-header-myplan {
  position: absolute;
  display: block;
  top: 150px;
  right: 0;
  width: 100%;
  z-index: +1;
  @media (min-width: 1160px) {
    position: fixed;
    top: 24.75vh;
    width: auto;
  }
}
.miepref-global-header-myplan__btn {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  @media (min-width: 1160px) {
    flex-direction: column;
    row-gap: 8px;
    padding: 12px;
    padding-right: 16px;
    background: rgba(#050505, 0.5);
    transform: translateX(4px);
    transition: background 0.3s, transform 0.3s;
  }
  .icon {
    color: var(--mieprefg-color-green-1);
    @media (min-width: 1160px) {
      color: #fff;
    }
  }
  .txt {
    color: var(--mieprefg-color-gray-800);
    font-weight: 500;
    @media (min-width: 1160px) {
      color: #fff;
      font-size: 12px;
      font-weight: bold;
      writing-mode: vertical-rl;
    }
  }
  &:hover {
    text-decoration: none;
    @media (min-width: 1160px) {
      background: rgba(#050505, 0.75);
      transform: translateX(0);
    }
  }
}

/* --------------------------------
  Feature Container
-------------------------------- */

@media (min-width: 1160px) {
  .miepref-global-header-feature-container {
    background-color: var(--mieprefg-color-green-1);
    display: flex;
    align-items: center;
    justify-items: center;
    height: var(--mieprefg-header-pc-sub-h);
    overflow: hidden;
    padding: 0 var(--mieprefg-header-side-gutter);
    margin: 0;
  }
}

.miepref-global-header-feature-list {
  display: block;
  width: 100%;
  max-width: 100%;
  margin: 0;
  padding: 0;
}
@media (min-width: 1160px) {
  .miepref-global-header-feature-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: stretch;
    margin: 0;
    padding: 0;
  }
}

.miepref-global-header-feature-list-item {
  text-decoration: none;
  padding: 0;
  margin: 0 0 16px 0;
  list-style: none;
  text-align: left;
  display: block;
  line-height: 1.5;
}
@media (min-width: 1160px) {
  .miepref-global-header-feature-list-item {
    display: flex;
    margin: 0;
    width: 16.666%;
    flex: 0 0 auto;
    text-align: left;
    align-items: center;
    justify-content: center;
    line-height: 1.1;
  }
}

.miepref-global-header-feature-list-item__link {
  display: flex;
  align-items: stretch;
  position: relative;
  text-decoration: none !important;
  color: var(--mieprefg-color-gray-800) !important;
  line-height: 1.2;
  font-size: 16px;
  padding: 0 24px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 500;
  @media (min-width: 1160px) {
    font-weight: 600;
  }
}

.miepref-global-header-feature-list-item__link:hover {
  text-decoration: none !important;
  color: var(--mieprefg-color-green-1) !important;
}
@media (min-width: 1160px) {
  .miepref-global-header-feature-list-item__link {
    padding: 0 2px;
    align-items: center;
    width: auto;
    overflow: hidden;
    color: white !important;
    line-height: 1.1;
  }
  .miepref-global-header-feature-list-item__link:hover {
    color: #e4f4f5 !important;
  }
}

.miepref-global-header-feature-list-item__icon {
  display: inline-block;
  flex: 0 0 auto;
  width: 12px;
  height: 12px;
  transform: rotate(45deg);
  background-color: var(--mieprefg-color-green-1);
  margin: 4px 4px 0 0;
}
@media (min-width: 1160px) {
  .miepref-global-header-feature-list-item__icon {
    background-color: white;
    margin-top: 1px;
  }
}

.miepref-global-header-feature-list-item__txt {
  display: inline-block;
  padding-left: 6px;
}
@media (min-width: 1160px) {
  .miepref-global-header-feature-list-item__txt {
    padding-left: 12px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
}
