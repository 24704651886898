// utilities
//

@use './variables' as *;
@use './mixins' as mix;
@use './functions' as func;
@use 'bootstrap' as bs;

// Object Fit
//

.object-fit-cover-center {
  object-fit: cover !important;
  object-position: center center !important;
}

.object-fit-contain-center {
  object-fit: contain !important;
  object-position: center center !important;
}

// Container Gutter
//

.container-gutter-60 {
  @include mix.col-space($m: 0.6);
}

.container-gutter-50 {
  @include mix.col-space($m: 0.5);
}

.container-gutter-40 {
  @include mix.col-space($m: 0.4);
}

// width
//

.min-w-auto {
  min-width: auto !important;
}

.width-fit-content {
  width: fit-content;
}
@each $breakpoint in map-keys($grid-breakpoints) {
  @include bs.media-breakpoint-up($breakpoint) {
    $infix: bs.breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $size, $length in $spacers {
      .w#{$infix}-#{$size} {
        width: $length !important;
      }
      .h#{$infix}-#{$size} {
        height: $length !important;
      }
    }
  }
}

// Counter
//

.cmn-counter {
  counter-increment: number;

  &__number {
    &::before {
      content: counter(number) '.';
    }
  }
}

// background
//
.bg-dark-40 {
  background-color: rgba($color-gray-900, 0.4);
}

// border
//

.border-dotted {
  --miepref-border-style: dotted;
}

.border-dashed {
  --miepref-border-style: dashed;
}

// font
//
.fs-small {
  font-size: bs.$font-size-sm;
}

.ff-montserrat {
  font-family: 'montserrat_bold', sans-serif;
}

// line-clamp
//
@for $i from 1 through 5 {
  .line-clamp-#{$i} {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: #{$i};
    overflow: hidden;
  }
}

// margin
//
.mt-n8vw {
  margin-top: -8vw;
}

// --------------------------------
// border-radius
// --------------------------------
@each $breakpoint in map-keys($grid-breakpoints) {
  @include bs.media-breakpoint-up($breakpoint) {
    $infix: bs.breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $size, $length in $spacers {
      .br#{$infix}-#{$size} {
        border-radius: $length !important;
      }
      .br-t#{$infix}-#{$size} {
        border-radius: $length $length 0 0 !important;
      }
      .br-e#{$infix}-#{$size} {
        border-radius: 0 $length $length 0 !important;
      }
      .br-b#{$infix}-#{$size} {
        border-radius: 0 0 $length $length !important;
      }
      .br-s#{$infix}-#{$size} {
        border-radius: $length 0 0 $length !important;
      }
      .br-ts#{$infix}-#{$size} {
        border-radius: $length 0 0 0 !important;
      }
      .br-te#{$infix}-#{$size} {
        border-radius: 0 $length 0 0 !important;
      }
      .br-be#{$infix}-#{$size} {
        border-radius: 0 0 $length 0 !important;
      }
      .br-bs#{$infix}-#{$size} {
        border-radius: 0 0 0 $length !important;
      }
    }
  }
}

// --------------------------------
// position
// --------------------------------
@each $breakpoint in map-keys($grid-breakpoints) {
  @include bs.media-breakpoint-up($breakpoint) {
    $infix: bs.breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $size, $length in $spacers {
      .t#{$infix}-#{$size} {
        top: $length !important;
      }
      .r#{$infix}-#{$size} {
        right: $length !important;
      }
      .b#{$infix}-#{$size} {
        bottom: $length !important;
      }
      .l#{$infix}-#{$size} {
        left: $length !important;
      }
    }
  }
}

// --------------------------------
// font-size
// --------------------------------
@each $breakpoint in map-keys($grid-breakpoints) {
  @include bs.media-breakpoint-up($breakpoint) {
    $infix: bs.breakpoint-infix($breakpoint, $grid-breakpoints);

    @for $i from 10 through 20 {
      .fs#{$infix}-#{$i} {
        font-size: #{$i}px;
      }
    }
  }
}

.fs-7 {
  font-size: $font-size-sm !important;
}

// Ratio (extends Bootstrap)
//

.ratio-lg-21x9 {
  @include bs.media-breakpoint-up(lg) {
    --miepref-aspect-ratio: calc(9 / 21 * 100%);
  }
}

.ratio-xl-16x9 {
  @include bs.media-breakpoint-up(xl) {
    --miepref-aspect-ratio: calc(9 / 16 * 100%);
  }
}

// --------------------------------
// cursor
// --------------------------------

.cursor-pointer {
  cursor: pointer;
}
