// List
//

@use '../core/variables' as *;
@use '../core/functions' as func;
@use 'bootstrap' as bs;

.list-cmn {
  padding: 0;
  margin: 0;

  &__item {
    list-style: none;
    margin: 0 0 0.25rem;
    padding: 1rem 0 0.25rem 1rem;
    border-bottom: 1px solid #222;
  }
}

.list-link {
  padding: 0;
  margin: 0;
  &__item {
    position: relative;
    list-style: none;
    margin: 0 0 0.25rem;
    padding: 0 0 0.25rem 1rem;
    border-bottom: 1px $color-silver solid;
    &::before {
      position: absolute;
      top: 0.75rem;
      left: 0;
      width: 0.5rem;
      height: 0.5rem;
      border-right: 1px $color-gray-400 solid;
      border-bottom: 1px $color-gray-400 solid;
      content: '';
      transform: rotate(-45deg);
    }
    & > a {
      color: $color-gray-800;
      text-decoration: none;
      transition: color 0.3s;
      &:hover {
        @media (hover: hover) {
          color: $color-green-2;
        }
      }
    }
  }
}

.num-list {
  counter-reset: number;
  padding: 0;
  list-style: none;
  &__item {
    position: relative;
    margin-bottom: 1.5rem;
    padding-left: 2rem;
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      color: $color-green-2;
      counter-increment: number;
      content: counter(number) '.';
      font-weight: bold;
    }
  }
}

.square-num-list {
  counter-reset: square_num;
  padding: 0 1rem;
  list-style: none;
  &__item {
    position: relative;
    margin-bottom: 1rem;
    padding-left: 3rem;
    font-weight: bold;
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      display: grid;
      place-items: center;
      width: 2rem;
      height: 2rem;
      counter-increment: square_num;
      content: counter(square_num);
      font-size: func.rem(14);
      font-weight: bold;
    }
    & > a {
      color: $color-green-2;
    }
  }
  &.--type_gold {
    .square-num-list__item::before {
      background: $color-gold;
      color: $color-brown;
    }
  }
}

.faq-list-item {
  margin-bottom: 1rem;
  border-bottom: 1px $color-silver solid;
  &__q,
  &__a {
    position: relative;
    padding: 1rem 0.5rem 1rem 3rem;
    &::before {
      position: absolute;
      top: 0.75rem;
      left: 0;
      display: grid;
      place-items: center;
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 2px;
      color: #fff;
      font-family: 'montserrat_bold', sans-serif;
      font-size: func.rem(20);
    }
  }
  &__q {
    &::before {
      background: $color-green-2;
      content: 'Q';
    }
  }
  &__a {
    &::before {
      background: $color-gray-600;
      content: 'A';
    }
  }
}

.guide-flow {
  @include bs.media-breakpoint-up(lg) {
    display: flex;
    column-gap: 40px;
  }
  &__item {
    position: relative;
    padding: clamp(8px, 1.25vw, 16px) clamp(12px, 1.875vw, 24px);
    background: $color-green-4;
    border-radius: 8px;
    @include bs.media-breakpoint-up(lg) {
      flex: 1 0 0;
    }
    &:not(:last-of-type) {
      margin-bottom: 40px;
      @include bs.media-breakpoint-up(lg) {
        margin-bottom: 0;
      }
      &::after {
        position: absolute;
        top: calc(100% + 10px);
        right: 50%;
        margin-right: -10px;
        display: block;
        width: 20px;
        height: 17.32px;
        background: $color-green;
        clip-path: polygon(0 0, 100% 0, 50% 100%);
        content: '';
        @include bs.media-breakpoint-up(lg) {
          top: 50%;
          right: -30px;
          margin-right: 0;
          rotate: -90deg;
        }
      }
    }
  }
}
.flow-num {
  margin-bottom: 0;
  color: $color-green;
  font-size: clamp(20px, 1.875vw, 24px);
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
}
.flow-txt {
  margin-bottom: 0;
  line-height: 1.5;
}
