@use '../core/variables' as *;

%btn-basic {
  width: 2.5rem;
  height: 2.5rem;
  border: none;
  border-radius: 50%;
  background: none;
  font-size: 1.5rem;
  text-decoration: none;
}
.add-btn-placed-on-pic {
  position: absolute;
  top: 0;
  right: 0;
  left: auto !important;
  width: 2.5rem;
  height: 2.5rem;
  i {
    pointer-events: none;
  }
  &__btn {
    @extend %btn-basic;
    color: #fff;
  }
  &__active-btn {
    @extend %btn-basic;
    color: #fb7185;
  }
}
