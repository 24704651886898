// course
//

@use '../core/variables' as *;
@use '../core/functions' as func;
@use 'bootstrap' as bs;

.course-nav {
  border-bottom: 2px $color-green-2 solid;
}
.course-nav-contents {
  display: flex;
  column-gap: clamp(0.5rem, 2.37vw, 1.5rem);
  width: 100%;
  margin: 0 auto -2px;
  padding: 0;
  color: $color-silver;
  @each $breakpoint, $container-max-width in $container-max-widths {
    @include bs.media-breakpoint-up($breakpoint, $grid-breakpoints) {
      max-width: $container-max-width;
    }
  }
  &__link {
    flex: 1 0 0;
    display: grid;
    place-items: center;
    padding: 0.5rem 1rem;
    min-height: 3.5rem;
    background: $color-green-4;
    border: 2px $color-green-2 solid;
    border-radius: 0.5rem 0.5rem 0 0;
    color: $color-green-2;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    transition: background 0.3s;
    &.is-current {
      background: #fff;
      border-bottom: none;
    }
    &:hover {
      @media (hover: hover) {
        background: $color-green-3;
        color: $color-green-2;
        text-decoration: none;
      }
      &.is-current {
        @media (hover: hover) {
          background: #fff;
        }
      }
    }
  }
}

// course detail
.course-spot {
  &:not(:last-child) {
    background-image: linear-gradient(
      $color-gray-800 0%,
      $color-gray-800 25%,
      #fff 25%,
      #fff 50%,
      $color-gray-800 50%,
      $color-gray-800 75%,
      #ffff 75%,
      #fff 100%
    );
    background-repeat: repeat-y;
    background-size: 1px 8px;
    background-position: 2.125rem 0;
  }
  &--start {
    .course-spot-contents__name {
      font-size: 1.25rem;
    }
  }
}
.course-spot-block {
  display: flex;
}
.course-spot-counter {
  width: 4.25em;
  &__txt {
    display: grid;
    place-items: center;
    width: 4.25em;
    height: 4.25rem;
    background: $color-green-4;
    color: $color-green-2;
    font-weight: bold;
  }
}
.course-spot-contents {
  flex-grow: 1;
  padding-left: 1rem;
  &__name {
    padding: 1rem 0;
    font-size: 1.25rem;
    font-weight: bold;
    line-height: 1.6;
    color: $color-green-2;
  }
  &__departure-time {
    margin-bottom: 0.5rem;
    font-weight: bold;
  }
  &__lead {
    margin-bottom: 1rem;
    color: $color-green-2;
    font-weight: bold;
  }
  &__img {
    width: 100%;
    margin-bottom: 1rem;
    @include bs.media-breakpoint-up(lg) {
      flex-shrink: 0;
      width: 72.36%;
      margin-bottom: 0;
      padding-right: 1.5rem;
    }
  }
  &__description {
    font-size: 0.9rem;
    padding-bottom: 1.5rem;
    iframe {
      aspect-ratio: 16 / 9;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

.course-spot-contents-point {
  @include bs.media-breakpoint-up(lg) {
    display: flex;
    align-items: center;
    width: 83.27%;
    padding-left: 4.18%;
  }
  &__txt {
    flex-grow: 1;
    position: relative;
    margin-bottom: 2rem;
    padding: 0.75rem 1.5rem;
    background: $color-gray-100;
    border-radius: 0.5rem;
    box-shadow: 0 3px 6px rgba(#000, 0.16);
    font-size: 0.9rem;
    @include bs.media-breakpoint-up(lg) {
      margin-right: 2rem;
      margin-bottom: 0;
    }
    &::after {
      position: absolute;
      top: 100%;
      left: 50%;
      width: 1.5rem;
      height: 1rem;
      background: $color-gray-100;
      clip-path: polygon(0 0, 50% 100%, 100% 0);
      content: '';
      transform: translateX(-50%);
      @include bs.media-breakpoint-up(lg) {
        top: 50%;
        left: 100%;
        width: 1rem;
        height: 1.5rem;
        clip-path: polygon(0 0, 0% 100%, 100% 50%);
        transform: translateY(-50%);
      }
    }
  }
  &__thumb {
    flex-shrink: 0;
    width: 7.5rem;
    height: 7.5rem;
    margin: 0 auto;
    border-radius: 50%;
    overflow: hidden;
    background-size: cover;
  }
}

.course-transit {
  display: flex;
  align-items: center;
  width: 100%;
  height: clamp(5rem, 15.94vw, 12.75rem);
  &__icon {
    width: 4.25em;
    span {
      display: grid;
      place-items: center;
      width: 2.75rem;
      height: 2.75rem;
      margin: 0 auto;
      background: #fff;
      border: 1px #838fdb solid;
      border-radius: 50%;
      color: #838fdb;
      font-size: 1.5rem;
    }
  }
  &__txt {
    padding-left: 1rem;
    font-size: 1rem;
    font-weight: bold;
    color: #838fdb;
  }
}

.btn-copy-course-wrap {
  background-image: linear-gradient($dark 0%, $dark 100%);
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 100% 1px;
}
