// feature
//

@use '../core/variables' as *;
@use '../core/functions' as func;
@use 'bootstrap' as bs;

.feature-index-list {
  &__title {
    height: (4 * 1.75) + em;
    @include bs.media-breakpoint-up(lg) {
      height: (3 * 1.75) + em;
    }
    & > span {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 4;
      overflow: hidden;
      @include bs.media-breakpoint-up(lg) {
        -webkit-line-clamp: 3;
      }
    }
  }
}
