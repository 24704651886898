// media
//

@use '../core/variables' as *;
@use '../core/functions' as func;
@use 'bootstrap' as bs;

// media indexページなどで使用したスタイル群
//

.media-heading {
  margin: 1.5rem 0 1.5rem;
  padding: 0;
  text-align: left;

  &__sub-txt {
    display: block;
    margin: 0 0 0.25rem;
    font-size: 1rem;
    color: $color-green-2;
    line-height: 1;
    font-family: 'montserrat_bold';
    font-weight: 700;
  }

  &__main-txt {
    display: block;
    font-size: 1.5rem;
    color: $color-gray-800;
    line-height: 1.3;
    font-family: 'noto_sans';
    font-weight: 700;
  }
}

.media-jumbotron {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  padding: 0rem;

  @include bs.media-breakpoint-up(lg) {
    flex-direction: row;
    justify-content: start;
    align-items: center;
    padding: 2rem;
  }

  &__pic {
    position: initial;
    object-fit: cover;
    width: 100%;
    height: 300px;
    margin: 0 0 2rem 0;

    @include bs.media-breakpoint-up(lg) {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      object-fit: cover;
      width: 100%;
      height: 100%;
      z-index: -1;
      margin: 0;
    }
  }

  &__contents {
    background-color: transparent;
    padding: 0;
    width: 100%;
    max-width: initial;

    @include bs.media-breakpoint-up(lg) {
      background-color: white;
      padding: 3rem 2rem;
      width: 80%;
      max-width: 400px;
    }
  }

  &__title {
    margin: 0 0 1.5rem 0;
    font-size: 1.25rem;
    color: $color-green-2;
    line-height: 1.2;
    padding: 0 0 1.5rem 0;
    border-bottom: 1px solid $color-green-2;
    text-align: center;
  }

  &__description {
    margin: 0 0 2rem 0;
    text-align: left;

    @include bs.media-breakpoint-up(lg) {
      text-align: center;
    }
  }

  &__btn {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0 1rem;
    height: 48px;
    background-color: $color-gray-800;
    color: white;
    text-decoration: none;
    transition: all 0.3s ease;
    width: 80%;
    max-width: 280px;

    &:hover {
      background-color: $color-gray-900;
    }
  }
}

.collaboration-list {
  list-style: none;
  padding: 0;
}

.collaboration-list-item {
  &__date {
    font-size: 0.875rem;
    line-height: 1.2;
    margin: 0 0 0.2rem 0;
  }

  &__contents {
    display: flex;
    gap: 0.75rem;
    flex-direction: column;
    align-items: start;
    line-height: 1.2;

    @include bs.media-breakpoint-up(lg) {
      flex-direction: row;
      align-items: center;
    }
  }

  &__title {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin: 0;
    line-height: 1.2;
  }

  &__icon {
    color: bs.$red-500;
  }

  &__link {
    color: bs.$gray-700;
    transition: all 0.3s ease;
    line-height: 1.2;

    &:hover {
      color: bs.$gray-900;
    }
  }
}

.press-release-list {
  list-style: none;
  padding: 0;
}

.press-release-list-item {
  &__date {
    font-size: 0.875rem;
    line-height: 1.2;
    margin: 0 0 0.2rem 0;
  }

  &__contents {
    display: flex;
    gap: 0.75rem;
    flex-direction: column;
    align-items: start;
    line-height: 1.2;

    @include bs.media-breakpoint-up(lg) {
      flex-direction: row;
      align-items: center;
    }
  }

  &__title {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin: 0;
    line-height: 1.2;
  }

  &__icon {
    color: bs.$red-500;
  }

  &__link {
    color: bs.$gray-700;
    transition: all 0.3s ease;
    line-height: 1.2;

    &:hover {
      color: bs.$gray-900;
    }
  }

  &__author {
    color: bs.$gray-600;
    font-size: 0.75rem;
    margin: 0;
  }
}

// mediaの下層ページなどで使用したスタイル群
//

.media-detail-heading {
  margin: 0 0 0.75rem 0;
  padding: 0.625rem 1rem;
  color: $color-green;
  background-color: $color-gray-100;
  border-top: 1px solid $color-green;
  border-bottom: 1px solid $color-green;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.2;
  word-break: break-all;
}

.media-card-detail-link-area {
  text-decoration: none;
  transition: all 0.3s ease;
  color: $color-gray-800;
  font-weight: bold;

  &:hover {
    color: $color-gray-900;
    text-decoration: underline;
  }
}

.media-open-cart-btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
  background-color: #ff6666;
  color: white;
  line-height: 1.2;
  transition: all 0.3s ease;
  font-size: 1rem;
  text-decoration: none;
  height: 48px;
  padding: 0 1.25rem;
  gap: 0.75rem;
  border: none;

  &:hover {
    background-color: darken(#ff6666, 15%);
  }
}

.media-add-cart-btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
  line-height: 1.2;
  transition: all 0.3s ease;
  font-size: 1rem;
  text-decoration: none;
  height: 48px;
  padding: 0 1.25rem;
  gap: 0.5rem;
  border: none;
  background-color: #ffdb65;
  color: $body-color;

  &:hover {
    background-color: darken(#ffdb65, 15%);
  }
}

.media-added-cart-btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
  line-height: 1.2;
  transition: all 0.3s ease;
  font-size: 1rem;
  text-decoration: none;
  height: 48px;
  padding: 0 1.25rem;
  gap: 0.5rem;
  border: none;
  background-color: $color-gray-300;
  color: $color-gray-700;

  &:hover {
    background-color: $color-gray-400;
  }
}

.media-apply-cart-btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
  background-color: #ff6666;
  color: white;
  line-height: 1.2;
  transition: all 0.3s ease;
  font-size: 1rem;
  text-decoration: none;
  height: 56px;
  padding: 0 1.25rem;
  gap: 0.75rem;
  border: none;

  &:hover {
    background-color: darken(#ff6666, 10%);
  }
}

.movie-list-item {
  cursor: pointer;
}
