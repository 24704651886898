@use '../core/variables' as v;
@use 'bootstrap' as bs;

.recommend-contents-popup {
  $right-side-lang-btn-offset: 80px;
  $bg-color: v.$color-gray-50;
  $border-color: v.$color-gray-200;

  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: calc(100% - $right-side-lang-btn-offset);
  height: auto;
  padding: 1rem 0.5rem;
  background-color: $bg-color;
  z-index: 1031; // [todo] hard cord
  border-radius: 0.25rem 0.25rem 0 0;
  border-top: 1px solid $border-color;
  border-left: 1px solid $border-color;
  border-right: 1px solid $border-color;
  transition: all 0.3s ease;

  @include bs.media-breakpoint-up(sm) {
    padding: 1rem;
  }

  &__handle {
    $handle-w: 48px;
    $handle-h: 24px;

    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: calc(-#{$handle-h} + 1px);
    left: 24px;
    width: $handle-w;
    height: $handle-h;
    background-color: $bg-color;
    cursor: pointer;
    border-radius: 0.5rem 0.5rem 0 0;
    border-top: 1px solid $border-color;
    border-left: 1px solid $border-color;
    border-right: 1px solid $border-color;
  }

  &__handle-icon {
    &.--down {
      transform: rotate(180deg);
    }
  }

  &__content {
    width: 100%;
    height: 100%;

    @include bs.media-breakpoint-up(sm) {
      gap: 0.85rem;
    }
  }

  &__carousel-area {
    position: relative;
  }
}

.recommend-contents-popup-title {
  display: flex;
  gap: 0.5rem;
  font-size: 0.75rem;
  color: v.$primary;
  justify-content: center;
  align-items: center;
  line-height: 1;
  margin: 0 0 0.5rem 0;

  @include bs.media-breakpoint-up(sm) {
    font-size: 1rem;
  }

  &__orn {
    display: flex;
    gap: 0.25rem;
    font-size: 0.5rem;
  }
}

.recommend-contents-popup-carousel {
  position: relative;

  // &__navs {}

  &__nav {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    background-color: v.$primary;
    padding: 0.5rem;
    border-radius: 0.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    line-height: 1;
    cursor: pointer;
    transition: all 0.3s ease;
    z-index: 2; // [todo] hard cord

    @include bs.media-breakpoint-up(sm) {
      width: 32px;
      height: 32px;
    }

    &:hover {
      background-color: darken(v.$primary, 8%);
    }

    &.--prev {
      left: -0.25rem;
    }

    &.--next {
      right: -0.25rem;
    }

    i {
      color: white;
      font-size: 1rem;
    }
  }

  &__item {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    text-decoration: none;
    transition: all 0.3s ease;
    padding: 0.5rem;
    border-radius: 0.5rem;

    @include bs.media-breakpoint-up(lg) {
      flex-direction: row;
      padding: 0.75rem;
    }

    &:hover {
      background-color: v.$color-gray-200;
    }
  }

  &__item-img-part {
    flex-shrink: 0;
    width: 100%;
    height: 80px;

    @include bs.media-breakpoint-up(sm) {
      height: 130px;
    }

    @include bs.media-breakpoint-up(md) {
      height: 110px;
    }

    @include bs.media-breakpoint-up(lg) {
      width: 120px;
      height: 80px;
    }
  }

  &__item-detail-part {
    flex: 1;
    width: 100%;
    @include bs.media-breakpoint-up(lg) {
      width: calc(100% - 120px - 1rem);
    }
  }

  &__item-title {
    font-size: 0.85rem;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    line-height: 1.2;
    margin-bottom: 0.25rem;
    font-weight: bold;
    color: v.$color-gray-800;
  }

  &__item-description {
    font-size: 0.75rem;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    color: v.$color-gray-600;
    line-height: 1.1;

    @include bs.media-breakpoint-up(md) {
      line-height: 1.6;
    }
  }
}
