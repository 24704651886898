// form
//

@use '../core/variables' as *;
@use '../core/functions' as func;
@use 'bootstrap' as bs;

.input-text {
  input[type='text'],
  input[type='email'],
  input[type='tel'] {
    width: 100%;
    padding: 0.25rem 0.75rem;
    background: #fff;
    border: 1px $color-silver solid;
    border-radius: 0;
    box-shadow: 1px 3px 4px rgba($color-silver, 0.5) inset;
    font-size: func.rem(18);
    transition: box-shadow 0.3s;
    &::placeholder {
      color: $color-silver;
    }
    &:focus {
      box-shadow: none;
    }
    &:invalid {
      outline-color: $color-red;
    }
  }
}
.input-zip {
  display: flex;
  align-items: center;
  &__item {
    position: relative;
    &:first-of-type {
      width: 8rem;
    }
    &:last-of-type {
      width: 16rem;
    }
  }
  &__item + &__item {
    padding-left: 1.5rem;
    &::before {
      position: absolute;
      top: 50%;
      left: 0.5rem;
      content: '-';
      transform: translateY(-50%);
    }
  }
}
.form-textarea {
  width: 100%;
  height: 10rem;
  padding: 0.25rem 0.75rem;
  background: #fff;
  border: 1px $color-silver solid;
  border-radius: 0;
  box-shadow: 1px 3px 4px rgba($color-silver, 0.5) inset;
  font-size: func.rem(18);
  transition: box-shadow 0.3s;
  &:focus {
    box-shadow: none;
  }
  &::placeholder {
    color: $color-silver;
  }
}

.form-contents {
  border-top: 1px $color-silver solid;
}
.form-contents-block {
  border: $color-silver solid;
  border-width: 0 1px 1px;
  &__title {
    position: relative;
    padding: 0.5rem 3.5rem;
    background: $color-gray-100;
    border-bottom: 1px $color-silver solid;
    font-size: func.rem(18);
    font-weight: bold;
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      padding: 0 0.5rem;
      background: $color-gray-800;
      color: #fff;
      content: '任意';
      font-size: func.rem(14);
      font-weight: bold;
    }
    &.is-must {
      &::before {
        background: $color-red;
        content: '必須';
      }
    }
  }
  &__input {
    padding: 1rem;
  }
}

.select-wrap {
  width: fit-content;
  border: 1px $color-gray-800 solid;
  border-radius: 0.25rem;
  select {
    margin: 0;
    padding: 0.5rem;
    background: none;
    border: none;
  }
}

.input-date {
  input[type='date'],
  input[type='datetime-local'] {
    width: 100%;
    padding: 0.25rem 0.75rem;
    background: #fff;
    border: 1px $color-silver solid;
    border-radius: 0;
    box-shadow: 1px 3px 4px rgba($color-silver, 0.5) inset;
    font-size: func.rem(18);
    transition: box-shadow 0.3s;
    &::placeholder {
      color: $color-silver;
    }
    &:focus {
      box-shadow: none;
    }
    &:invalid {
      outline-color: $color-red;
    }
  }
}

$form-theme-colors: (
  '0': (
    base: $color-green-2,
    pale: $color-green-3,
    title_bg: $color-green-4,
    text: $color-green-2,
  ),
  '1': (
    base: #38bdf8,
    pale: #38bdf8,
    title_bg: #e0f2fe,
    text: $color-gray-800,
  ),
  '2': (
    base: #f0abfc,
    pale: #f0abfc,
    title_bg: #f3e8ff,
    text: $color-gray-800,
  ),
  '3': (
    base: #bef264,
    pale: #bef264,
    title_bg: #ecfccb,
    text: $color-gray-800,
  ),
  '4': (
    base: #ca8a04,
    pale: #ca8a04,
    title_bg: #ffedd5,
    text: $color-gray-800,
  ),
  '5': (
    base: #60a5fa,
    pale: #60a5fa,
    title_bg: #dbeafe,
    text: $color-gray-800,
  ),
);

@each $theme-name, $theme-info in $form-theme-colors {
  [data-form-theme='#{$theme-name}'] {
    .heading-1-2 {
      background-color: map-get($theme-info, title_bg);
      border-bottom-color: map-get($theme-info, pale);
    }
    .heading-2-2 {
      border-bottom-color: map-get($theme-info, base);
      border-left-color: map-get($theme-info, base);
    }
    .heading-2-3 {
      border-bottom-color: map-get($theme-info, base);
      color: map-get($theme-info, text);
    }
  }
}
