// Carousel
//
// カルーセル要素にはほぼSwiperプラグインを利用するため
// そのパーツを作ることとなる
//
// 細かな補助パーツは、swiperの種類別に操作できるようにしている。
// 現時点で全て同じスタイルがあたっているが、変更可能 (22.10.05)

@use '../core/variables' as *;
@use '../core/functions' as func;
@use 'bootstrap' as bs;

@mixin carousel-nav {
  position: absolute;
  top: 50%;
  display: grid;
  place-items: center;
  width: 2rem;
  height: 2rem;
  background-color: rgba($color-gray-800, 0.8);
  border-radius: 2px;
  color: #fff;
  font-size: func.rem(28);
  transform: translateY(-50%);
  transition: background 0.3s;
  z-index: 2;

  @include bs.media-breakpoint-up(lg) {
    width: 2.5rem;
    height: 2.5rem;
  }
}

.cmn-carousel-nav-next,
.single-carousel-nav-next,
.gallery-carousel-nav-next,
.circle-carousel-nav-next,
.photo-carousel-nav-next,
.top-mv-carousel-nav-next,
.top-map-card-carousel-nav-next {
  @include carousel-nav;
  right: 0;
  &:hover {
    @media (hover: hover) {
      background: $color-gray-800;
    }
  }
}

.cmn-carousel-nav-prev,
.single-carousel-nav-prev,
.gallery-carousel-nav-prev,
.circle-carousel-nav-prev,
.photo-carousel-nav-prev,
.top-mv-carousel-nav-prev,
.top-map-card-carousel-nav-prev {
  @include carousel-nav;
  left: 0;
  &:hover {
    @media (hover: hover) {
      background: $color-gray-800;
    }
  }
}

.top-map-card-carousel-nav-prev.swiper-button-disabled,
.top-map-card-carousel-nav-next.swiper-button-disabled {
  cursor: not-allowed;
  background-color: rgba($color-gray-400, 0.8);
}

[data-role='single-swiper'] {
  padding-bottom: clamp(3.5rem, 6.875vw, 4rem);
  .swiper-slide {
    position: relative;
    &__caption {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      margin-bottom: 0;
      padding: 0.5rem 1rem;
      background: rgba($color-gray-900, 0.4);
      color: #fff;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
  [class^='single-carousel-nav'] {
    top: clamp(calc(50% - 1.5rem), calc(50% - 3.4375vw), calc(50% - 2.75rem));
  }
  .swiper-pagination {
    bottom: 1.5rem;
    font-size: 10px;
    line-height: 1;
  }
  .swiper-pagination-bullet {
    width: 1rem;
    height: 1rem;
    margin-inline: 8px !important;
    background: $color-green-4;
    border-radius: 2px;
    opacity: 1;
  }
  .swiper-pagination-bullet-active {
    background: $color-green-2;
  }
}

// .single-carousel-pagenation {}
[data-role='gallery-thumb-swiper'] {
  .swiper-slide {
    opacity: 0.5;
    transition: opacity 0.3s;
    cursor: pointer;
    &.swiper-slide-thumb-active {
      opacity: 1;
    }
  }
}

.near-contents {
  [data-role='cmn-swiper'] {
    .swiper-slide {
      .ratio {
        img {
          width: auto;
          height: 100%;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
    .cmn-carousel-nav-prev,
    .cmn-carousel-nav-next {
      top: calc((((100vw - 24px) / 2) * (3 / 4)) / 2);
      width: 2rem;
      height: 2rem;
      font-size: 1rem;
      @include bs.media-breakpoint-up(md) {
        top: calc((((720px - 64px) / 3) * (3 / 4)) / 2);
      }
      @include bs.media-breakpoint-up(lg) {
        top: calc(((((980px * (3 / 4)) - 64px) / 3) * (3 / 4)) / 2);
      }
      @include bs.media-breakpoint-up(xl) {
        top: calc(((((1216px * (3 / 4)) - 64px) / 3) * (3 / 4)) / 2);
      }
    }
  }
}
